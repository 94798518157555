<div class="icon-box " (click)="onClick()" [ngClass]="{'bg-color': !loader}">
    <mat-icon *ngIf="loader">{{icon}} </mat-icon>
    <div *ngIf="!loader" class="d-flex justify-content-center " >
        <div class="spinner-border text-secondary" role="status">        
        </div>
    </div>  
    <h5 *ngIf="!imgurl"  class="heading-Box"> {{heading}} </h5>
    <img *ngIf="imgurl" style="width:70px; height:38px; padding-bottom: 3px; margin-top: 8px;" [src]="imgurl" alt="">
</div>


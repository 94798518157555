import { Component, OnInit, Input, Output, EventEmitter, OnChanges, HostListener } from '@angular/core';
import { ThemeService } from 'src/app/services/base/theme.service';

@Component({
  selector: 'app-my-list-http',
  templateUrl: './my-list-http.component.html',
  styleUrls: ['./my-list-http.component.scss']
})
export class MyListHttpComponent implements OnInit, OnChanges {

  @Input() data: any[] = [];
  @Input() title: string = "";
  @Input() icon: string = "";
  @Input() search: boolean = false;
  @Input() canClick: boolean = true;
  @Output() myClick = new EventEmitter<any>();;
  @Input() image: boolean = false;
  searchList: any[] = [];

  @Input() val1: any = null;
  @Input() val2: any = null;
  @Input() val3: any = null;

  @Input() isFullTab: boolean = true;

  @Input() bottomButtonText: string = "";
  @Output() bottomButtonClick = new EventEmitter<any>();;
  @Input() bottomButtonIcon: string = "";

  @Output() moreButtonClick = new EventEmitter<any>();;

  @Input() expandMore: boolean = false;

  constructor(public theme: ThemeService) { }

  ngOnInit(): void {
  }

  isLoaded: boolean = false;
  ngOnChanges(): void {
    this.searchList = this.data;
    this.isLoaded = true;
  }


  onClick(row: any) {
    if (this.canClick == true) {
      if (this.isFullTab == true)
        this.myClick.emit(row);
    }
  }

  onIconClick(row: any) {
    if (this.canClick == true) {
      if (this.isFullTab == false)
        this.myClick.emit(row);
    }
  }

  getValue(option) {
    return option[this.title];
  }

  applyFilter(value) {
    if (value != '' && value != null && value != undefined) {
      this.searchList = this.data.filter(res => {
        return res[this.title].toString().toLowerCase().indexOf(value.toLowerCase()) !== -1;
      })
    }
    else
      this.searchList = this.data;
  }

  getVal1(option) {
    if (this.val1)
      return option[this.val1.name] == null ? '' : option[this.val1.name];
    else
      return '';
  }

  getVal2(option) {
    if (this.val2)
      return option[this.val2.name] == null ? '' : option[this.val2.name];
    else
      return '';
  }

  getVal3(option) {
    if (this.val3)
      return option[this.val3.name] == null ? '' : option[this.val3.name];
    else
      return '';
  }

  bottomBtnClick(row: any) {
    this.bottomButtonClick.emit(row);
  }

  moreBtnClick() {
    this.moreButtonClick.emit();
  }

  @Output() scrollingFinished = new EventEmitter<void>();

  onScrollingFinished() {
    this.scrollingFinished.emit();
  }



  // @Input() data:any[]=[];
  // @Input() title:string="";
  // @Input() icon:string="";
  // @Input() search:boolean=false;
  // @Output() myClick = new EventEmitter<any>();;
  // @Input() image:boolean=false;
  // searchList:any[]=[];
  // txt:any=null;
  // msg:any=null;
  // constructor() { }

  // ngOnInit(): void {
  // }

  // ngOnChanges(): void {
  //   this.searchList = this.data;
  // }


  // onClick(row:any){
  //   this.myClick.emit(row);
  // }

  // getValue(option){
  //   return option[this.title];
  // }

  // applyFilter(value) {    
  //   if (value != '' && value != null && value != undefined) {
  //     this.searchList = this.data.filter(res => {        
  //       //return res[this.title].toString().toLowerCase().indexOf(value) !== -1;
  //       return res[this.title].toString().toLowerCase().includes(value.toLowerCase());
  //     })      
  //   }
  //   else
  //     this.searchList = this.data;
  // }


}
<div class="d-flex">
    <input type="search" (search)="onChange($event)"  #swiper class="w-100 form-control form-control-sm mysearch" (keyup)="onChange($event)" placeholder="Search"  autocomplete="off">
    <!-- <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
            <mat-icon>close</mat-icon>
    </button> -->
    <!-- <mat-icon class="crossButton"   (click)="onClear()">close</mat-icon> -->
</div>

<!-- <mat-form-field class="w-100" appearance="outline" [style.fontSize.px]="17">
        <input matInput type="text" [(ngModel)]="value" placeholder="Search"  autocomplete="off" (keyup)="onChange($event)">
        <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
          <mat-icon>close</mat-icon>
        </button>
</mat-form-field> -->
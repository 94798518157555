<div class="myForm">
    <form class=" mt-4">

        <div class="d-flex justify-content-center pb-2">
            <div class="logo">
                <img src="assets/img/BioMed_logo.png" alt="Logo" style="width: 100px;">
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 pt-5 text-center">
                <app-my-heading heading="Log In"></app-my-heading>
            </div>
        </div>


        <div class="row">
            <app-my-input label="User Id" class="col-md-12" required="true" name="UserId"
                [(ngModel)]="userId">
            </app-my-input>
            <app-my-input-password label="Password" class="col-md-12" required="true" name="Password"
                [(ngModel)]="password">
            </app-my-input-password>
        </div>

        <mat-error>{{errorMessage}}</mat-error>       

        <div class="row">
            <div class="col-md-12 mt-4">
                <input [disabled]="validate()" class="btn btn-block w-100" [class]="theme.myButtonBgColor"
                type="button" (click)="openLink()" value="Sign In">
              
                <!-- <button [disabled]="validate()" class="btn btn-block w-100" [class]="theme.myButtonBgColor" type="button"
                    (click)="openLink()">
                    Sign In
                </button> -->

            </div>
        </div>        

    </form>
</div>
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ThemeService } from 'src/app/services/base/theme.service';
@Component({
  selector: 'app-ribbon-delete-dialog',
  templateUrl: './ribbon-delete-dialog.component.html',
  styleUrls: ['./ribbon-delete-dialog.component.scss']
})
export class RibbonDeleteDialogComponent implements OnInit {
  @Input() valid:boolean = false;
  @Output() myClick =  new EventEmitter();
  
  constructor(public theme:ThemeService) { }

  ngOnInit(): void {
  }

  onClick(){
    this.myClick.emit();
  }

}

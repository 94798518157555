import { Injectable } from '@angular/core';
import { SqlService } from './sql.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  IsAdmin:boolean=false;
  username = "";
  profileName="";
  constructor(
    public sql:SqlService,
    public api:ApiService
    ) { }
}

<button mat-stroked-button type="button" [disabled]="valid" (click)="onClick()" onclick="this.blur();"
    class="w-100 text-start" [ngClass]="label && (text == '' || text == null || text == undefined) ? 'thisBtn' : 'thisBtn-top'" >
    <!-- <div class="my-form-field-outline-gap"></div> -->
    <!-- <span class="lbl" *ngIf="label && (text == '' || text == null || text == undefined)" >{{label}}</span> -->
    <label [ngClass]="label && (text == '' || text == null || text == undefined) ? 'lbl' : 'lbl-top'" >
        <span style="background-color:#ffffff; padding-left: 6px;">{{label}}</span>
        <span style="background-color:#ffffff; padding-right: 6px;" *ngIf="required"> *</span>
    </label>

    <div style="display: flex;align-items: center;justify-content: space-between;">
        <div class="textarea">

            <span>{{text}}</span>

        </div>
        <mat-icon *ngIf="icon" class="" style="color:rgba(0,0,0,.54)">arrow_drop_down</mat-icon>
    </div>
    
</button>
<mat-error class="ms-2" style="font-size:75%; margin-bottom: 5px;" *ngIf="(name.invalid && showError) || name.dirty  ">This field is required</mat-error>
<!-- name: {{name.invalid}} || dirty: {{ name.dirty}} || showerror: {{showError}} -->

<mat-form-field style="display:none;">    
    <input 
    type="text"
    matInput  
    autocomplete="off"
    #name="ngModel"
    [required]="required" 
    [(ngModel)]="model"     
    (ngModelChange)="onChange(model)">            
</mat-form-field>
import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { MyFormDialogComponent } from 'src/app/shared/Dialog/my-form-dialog/my-form-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  dialogRef:any;
  dialogRefSmall:any;

  constructor(
    public dialog: MatDialog
  ) { }

  openDialog(component, Id?:number, data?:any): void {
    //const dialogRef = this.dialog.open(MyFormDialogComponent, {
     this.dialogRef = this.dialog.open(MyFormDialogComponent, {
      disableClose: true ,      
      height:"100%",
      maxHeight:"100vh",
      width:"100vw",
      minWidth:"100vw",
      panelClass:'dialog-panel',
      position: { right: 0 + 'px', top: 0 + 'px' },   
      autoFocus: false ,
      data: { component: component, primaryKey: Id, data: data}
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');      
    });
    
  }
  openCustomizeDialog(component, Id?:number, data?:any): void {
    //const dialogRef = this.dialog.open(MyFormDialogComponent, {
     this.dialogRef = this.dialog.open(MyFormDialogComponent, {
      // disableClose: true ,  
      height:"70%",
      maxHeight:"100%",
      width:"100%",
      minWidth: "60%",
      
      panelClass:'dialog-panel',
      position: { right: 10 + '%', top: 5 + '%' ,left:10 + '%',bottom:0 + '%'},   
      autoFocus: false ,
      data: { component: component, primaryKey: Id, data: data}
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');      
    });
    
  }

  closeDialog(data?:any){
    this.dialogRef.close(data);
  }

  openSmallDialog(component, data?:any): void {
    //const dialogRef = this.dialog.open(MyFormDialogComponent, {
     this.dialogRefSmall = this.dialog.open(MyFormDialogComponent, {
      //disableClose: true ,      
      //height:"100%",
      //maxHeight:"100vh",
      //panelClass:'dialog-panel',
      //position: { right: 0 + 'px', top: 0 + 'px' },   
      autoFocus: false ,
      data: { component: component, data: data }
    });

    this.dialogRefSmall.afterClosed().subscribe(result => {
      console.log('The dialog was closed');      
    });
    
  }

  closeSmallDialog(data?:any){
    this.dialogRefSmall.close(data);
  }

}

<!-- <div class="btnGroup">        
    <app-my-small-button  title="Copy Link" icon="link" (myClick)="copylink()"></app-my-small-button>        
    <app-my-button  title="Download" icon="download" (myClick)="download()"></app-my-button>        
</div>
 -->

 <div class="row card-margin">
        <div class="col-md-12 content-area">    
            <div class="w-100 text-center">
                <app-my-small-button  icon="close" mat-dialog-close></app-my-small-button>        
            </div>                           
            
            <br><br>
            <div class="btns">
              
                    
                    <app-my-button  title="Download" icon="download" (myClick)="download()"></app-my-button>    
                    <app-my-button  title="View" icon="arrow_forward_ios" (myClick)="view()"></app-my-button>               
                    <!-- <app-my-small-button  title="Copy Link" icon="link" (myClick)="copylink()"></app-my-small-button>         -->
            </div>            
        </div>
</div>
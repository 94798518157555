import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DialogService } from 'src/app/services/base/dialog.service';

@Component({
  selector: 'app-add-view-dialog',
  templateUrl: './add-view-dialog.component.html',
  styleUrls: ['./add-view-dialog.component.scss']
})
export class AddViewDialogComponent implements OnInit {

  constructor(public dialogService:DialogService) { }

  ngOnInit(): void {
  }

  onClick(mode:string){
    this.dialogService.closeSmallDialog(mode);    
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as customConfig from 'src/assets/customConfig.json';

@Injectable({
  providedIn: 'root'
})
export class SqlService {

  //readonly rootUrl: string = "http://localhost:57046/";
  readonly rootUrl: string = customConfig.cSharpApi; 

  constructor(public http: HttpClient) {
  }

  async get(query: string) {
    return await this.http.get(this.rootUrl + query).toPromise();
  }

  async getById(query: string, ID: number) {
    return await this.http.get(this.rootUrl + query + "(" + ID + ")").toPromise();
  }

  post(controller: string, jsonBody: any) {
    return this.http.post(this.rootUrl + controller, jsonBody);
  }

  update(controller: string, jsonBody: any, ID: number) {
    return this.http.put(this.rootUrl + controller, jsonBody);
  }

  delete(controller: string, ID: number) {
    return this.http.delete(this.rootUrl + controller + "/" + ID);
  }

}

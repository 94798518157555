import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ThemeService } from 'src/app/services/base/theme.service';
import { Router } from '@angular/router';
import { AuthenticateService } from 'src/app/services/base/authenticate.service';
import { RouteService } from 'src/app/services/base/route.service';
import { UserService } from 'src/app/services/base/user.service';
import { AuthenticationService } from 'src/app/helper/authentication.service';
import { BaseService } from 'src/app/services/base/base.service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit {
  @Input() appName: string = "";
  @Input() subAppName: string = "";
  @Output() menuClick =  new EventEmitter<any>();
  @Output() appClick =  new EventEmitter<any>();
  @Output() subAppClick =  new EventEmitter<any>();
  @Output() logoutClick =  new EventEmitter<any>();
  @Output() backClick =  new EventEmitter<any>();


  constructor(
    public theme:ThemeService , 
    public router: Router,
    public authenticate:AuthenticateService,
    public routeService:RouteService,
    public authenticationService:AuthenticationService,
    public userService:UserService,
    public baseService:BaseService
     ) { }

  ngOnInit(): void {

    this.getUserIsAdmin();
  }

  onMenuClick(){
    this.menuClick.emit();
    //this.router.navigate([this.routeService.openRoute('fredd', 'regions')]);
  }


  onAppClick(){
    this.appClick.emit();
  }

  onSubAppClick(){
    this.subAppClick.emit();
  }

  onlogoutClick(){
    this.logoutClick.emit();
  }

  login(){
    this.router.navigate(['/login']);
  }

  signup(formType:string){
    this.router.navigate(['/signup'], { queryParams: { type: formType } });
  }

  onBackClick(){
    this.backClick.emit();
  }
  logout() { // Add log out function here

    this.authenticationService.logout()

     this.authenticate.isLoggedIn = false;      

    //  this.authService.logoutRedirect({

    //    postLogoutRedirectUri: 'http://localhost:4200'

    //  });

  }

 

  openAdminPortal(){

    this.router.navigate(['app/home/admin/portal'])

  }

  getUserIsAdmin(){
    this.userService.getCurrecntUser().subscribe((res:any)=>{
      this.userService.getCurrecntUser().subscribe((res:any)=>{
        this.baseService.username = res.name;
        var splitname = this.baseService.username.split(" ");
        if(splitname.length  > 0){
          this.baseService.profileName = splitname[0].substring(0,1);
          if(splitname.length  > 1)
            this.baseService.profileName += splitname[1].substring(0,1);
            
          this.baseService.profileName = this.baseService.profileName.toUpperCase();
        }
        if(res.users_Admin.length > 0){
          this.baseService.IsAdmin = true;
        }
      })
      if(res.users_Admin.length > 0){
        this.baseService.IsAdmin = true;
      }
    })
  }
}

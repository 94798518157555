import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DialogService } from 'src/app/services/base/dialog.service';

@Component({
  selector: 'app-download-copy',
  templateUrl: './download-copy.component.html',
  styleUrls: ['./download-copy.component.scss']
})
export class DownloadCopyComponent implements OnInit {

  /*constructor(private _bottomSheetRef: MatBottomSheetRef<DownloadCopyComponent>) { }
    
  ngOnInit(): void {
  }

  download(){
    this._bottomSheetRef.dismiss("download");
    event.preventDefault();
  }

  copylink(){
    this._bottomSheetRef.dismiss("copyLink");
    event.preventDefault();
  }

  openinTab(){
    this._bottomSheetRef.dismiss("openIntab");
  }
  */

 constructor(public dialogService:DialogService) { }
  

 
 ngOnInit(): void {
 }

 download(){
   
   this.dialogService.closeSmallDialog("download");
 }

 copylink(){
  this.dialogService.closeSmallDialog("copyLink");   
 }

 view(){
  this.dialogService.closeSmallDialog("view");   
 }

 openinTab(){
  this.dialogService.closeSmallDialog("openIntab");   
 }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackbarService } from './snackbar.service';

import * as customConfig from 'src/assets/customConfig.json';
import { catchError, finalize } from 'rxjs/operators';
import { empty, throwError } from 'rxjs';
import { UserService } from 'src/app/services/base/user.service';

class User{
  Email:string = null;
  Id:number = null;
  Title:string = null;
  IsSiteAdmin:boolean = false;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  //readonly rootUrl: string = "https://sharepointapi.azure-api.net/fredd/v1/getdata/";
  readonly rootUrl: string = customConfig.freddApi;                            
  user:User = null;
  permission: string = "";

  constructor(
    public http: HttpClient,  
    private spinner: NgxSpinnerService,
    private snackBar:SnackbarService,
    private userService:UserService,
    ) {
  }

  async get(query: string) {
    return await this.http.get(this.rootUrl + "/v1/getdata" + query).toPromise();
  }

  async getById(query: string, ID: number) {
    return await this.http.get(this.rootUrl + query + "(" + ID + ")").toPromise();
  }

  post(controller: string, jsonBody: any) {
    //return this.http.post(this.rootUrl + controller, jsonBody, this.getPostHeaders());
    return this.http.post(this.rootUrl + "/v1/getdata" , jsonBody, this.getHeaders());
  }

  
  getHeaders(){
    const httpOptions = {
      headers: new HttpHeaders({                        
        'Ocp-Apim-Subscription-Key' : customConfig.azureSubscriptionKey 
      })      
    };

    return httpOptions;
  }




  getPostHeaders(){
    const httpOptions = {
      headers: new HttpHeaders({                
        'Content-Type':'application/json',
        'charset' : 'utf-8'
      })      
    };

    return httpOptions;
  }

  update(controller: string, jsonBody: any, ID: number) {
    return this.http.put(this.rootUrl + controller, jsonBody);
  }

  delete(controller: string, ID: number) {
    return this.http.delete(this.rootUrl + controller + "/" + ID);
  }

  fetch(query: string, fileURL: any, filename: string) {
    var url = customConfig.freddDocumentApi;
    this.spinner.show();
    // fetch(this.rootUrl + query, {
    fetch(url, {
      body: JSON.stringify({ "FileURL": fileURL }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Ocp-Apim-Subscription-Key':customConfig.azureSubscriptionKey
      },
      }).then(res => res.blob())
      .then(blob => {
          this.spinner.hide();
          
          const a = document.createElement('a')

          var file = window.URL.createObjectURL(blob);

          a.href = file

          a.download = filename+'.pdf';

          a.click();

          URL.revokeObjectURL(file);

          // window.location.assign(file);

          //window.open(file,"_blank");

         

          this.snackBar.openSuccess("Document downloaded successfully!","top");
      });

    // .then(res => res.blob()).then(res => {
    //   const blob = new Blob([res], { type: 'application/pdf' });
    //   const downloadUrl = URL.createObjectURL(blob);
      
    //   console.log('File Path',downloadUrl);

    //   this.spinner.hide();
    //   const a = document.createElement("a");
    //   a.href = downloadUrl;
    //   a.download = filename;
    //   document.body.appendChild(a);
    //   a.click();
    //   this.snackBar.openSnackBar("Document downloaded successfully!");
      
    // })

    //return this.http.post(this.rootUrl + controller, jsonBody, this.getPostHeaders());
  }



 async getUser() {
  // if (this.userService.profile == null) {
  //   let profile = await this.userService.getProfile();
  // }
  
  // if(this.user != null)
  //   return;
    
  //   var obj = {
  //     "UserEmail": this.userService.profile.mail 
  //   }
  
  //  await this.http.post(this.rootUrl + "/get/user/manual/paths/invoke", obj).pipe(
  //     catchError(error => {
  //       //error.status == 404
  //       //return empty;
  //       this.permission = "no-access";
  //       return throwError(error);
  //     }),
  //     finalize(() => {

  //       // Sameer:01/31/2022this.isLoading = false
  //     }),
  //   ).toPromise().then(async res => {
  //     this.user = res['d'];
      
  //     await this.getUserGroups().toPromise().then(res => {
  //       console.log("User Groups FREDD", res['d'].results);

  //       var groups = res['d'].results as any[];

  //       this.permission =  groups.length > 0 ? "owner" : "no-access";
  //     })
  //   },error =>{
  //     console.log("my error")
  //   });
  }

  CheckAcces(){
    if(this.permission != "" && this.permission != "no-access")
      return true

    return false;
  }

  //Check permission
  CP():boolean{
    if(this.permission == "owner" || this.permission == "member")
      return true;
    
    return false;
  }

  getUserGroups() {  
    return this.http.get(this.rootUrl + "/get/user/groups/manual/paths/invoke/" + this.user.Id, this.getHeaders());
  }


}

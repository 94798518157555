<mat-selection-list #module [multiple]="false">
        <mat-list-item style="margin-bottom: 50px">                
        <!-- <img src="assets/img/BioMed_logo.png" alt="" style="height: 105px;width: 500px;margin-top: 50px;">         -->
        <h1 style="
        font-size: 28px !important;
        font-weight: 500;   
        margin-top: 30%;
        margin-left: 20%;">FREDD</h1>
        </mat-list-item>
        <mat-divider></mat-divider>
        <!-- <mat-list-option *ngFor="let m of modules" [value]="shoe">
                <div class="custom-list" (click)="openLink(m.module,m.routeName);">
                        <mat-icon>{{m.icon}}</mat-icon> <span>{{m.title}}</span>
                        <mat-divider></mat-divider>
                </div>
        </mat-list-option> -->
        <mat-list-option>
                <div class="custom-list" (click)="openHome()">
                        <mat-icon>home</mat-icon> <span>Home</span>
                        <mat-divider></mat-divider>
                </div>
        </mat-list-option>
        <mat-list-option>
                <div class="custom-list" (click)="logout()">
                        <mat-icon>logout</mat-icon> <span>Logout</span>
                        <mat-divider></mat-divider>
                </div>
        </mat-list-option>
</mat-selection-list>
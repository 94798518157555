<button mat-stroked-button type="button" [disabled]="valid" (click)="onClick()" class="w-100 text-start"
    style="height:35px;    margin-bottom: 15px; background-color: white; border-radius:18px;">
    <!-- <div class="my-form-field-outline-gap"></div> -->
    <!-- <span class="lbl" *ngIf="label">{{label}}</span> -->

    <div class="textarea">
        <mat-icon *ngIf="icon" class="me-1" [class]="theme.myIconColor">{{icon}}</mat-icon>
        <span>{{text == '' || text == null ? label : text}}</span>
    </div>

</button>
<div class="row card-margin">
        <div class="col-md-12 content-area">                   
            <br>
            <h3>Are you sure you want to delete this record?</h3>
            <div style="display: flex;justify-content: space-evenly;">
                <app-my-small-button title="No" icon="close"  mat-dialog-close></app-my-small-button>
                <app-my-button title="Yes" icon="done" (click)="onClose()"></app-my-button>                
            </div>
            
        </div>
</div>
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { ThemeService } from 'src/app/services/base/theme.service';

@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss']
})
export class TableViewComponent implements OnInit {
    
  @ViewChild(MatSort) sort: MatSort;  
  @Input() columns: any[] =[];
  @Input() dataSource:any[]=[];    
  data: MatTableDataSource<any>;
  displayedColumns: string[] =[];   
  @Input() listTitle:string="";
  @Input() listIcon:string="";  
  @Input() listVal1: any = null;
  @Input() listVal2: any = null;
  @Input() listVal3: any = null;
  

  constructor(public theme:ThemeService) {
   
   }

  ngOnInit(): void {
    // Inserting a column for add and edit button
    this.columns.splice(0, 0, {
      columnDef: 'action',
      header: '',
      type: 'button',
      cell: (element: any) => `${element.action}`,
      filterData: [],
    })

    this.setTable();


  }

  msg(row){
    console.log(row)
  }

  ngAfterViewInit() {

  }

  ngOnChanges(): void {
   this.setTable();
  }

  

  setTable(){
    this.data = new MatTableDataSource(this.dataSource);
    this.displayedColumns = this.columns.map(c => c.columnDef);    
    this.data.sort = this.sort;       
  }



   



   
  
}

<!-- <div class="row" style="margin-top: -4px;">
    <div class="col-md-12">
        <div class="d-flex bd-highlight justify-content-end">
            <div class="p-2 bd-highlight">
                <button mat-raised-button style="margin-top: 7px;" class="shadow ms-1" *ngIf="filter">
                    <mat-icon class="add-icon" [class]="theme.myIconColor">filter_alt</mat-icon> Filter
                </button>
            </div>
        </div>
    </div>
</div> -->


<div class="table-responsive mat-elevation-z8 " style="margin-top: 10px; ">

    <table mat-table [dataSource]="data" matSort class="mat-elevation-z8 w-100">
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">

            <th mat-header-cell *matHeaderCellDef>
                <div class=" pt-3 pb-1" mat-sort-header>
                    {{column.header}}
                </div>
                <app-my-table-filter *ngIf="filter" [hidden]="false" label="Filter" [data]="column.filterData"
                    [title]="column.header" [name]="column.columnDef" [model]="column.filterValue"
                    (change)="filterChange(column,$event)" class="w-100 mt-3">
                </app-my-table-filter>
            </th>

            <td mat-cell *matCellDef="let row">
                {{ column.type == 'currency' ? (column.cell(row) | currency) : column.type == 'date' ?  (column.cell(row) | date:'MM/dd/yyyy') : column.cell(row) }}
            </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <!-- Group header -->
        <ng-container matColumnDef="groupHeader">
            <td colspan="999" mat-cell *matCellDef="let groupBy"><strong>{{groupBy.initial}}</strong></td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </tr>
        
    </table>

    <mat-paginator *ngIf="paging" [pageSizeOptions]="[5, 10, 20]" [pageSize]="20" showFirstLastButtons
        aria-label="Select page of periodic elements">
    </mat-paginator>

</div>
import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/base/dialog.service';

@Component({
  selector: 'app-edit-delete-dialog',
  templateUrl: './edit-delete-dialog.component.html',
  styleUrls: ['./edit-delete-dialog.component.scss']
})
export class EditDeleteDialogComponent implements OnInit {

  constructor(public dialogService:DialogService) { }

  ngOnInit(): void {
  }

  onClick(mode:string){
    this.dialogService.closeSmallDialog(mode);    
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { ThemeService } from 'src/app/services/base/theme.service';

@Component({
  selector: 'app-my-image-box',
  templateUrl: './my-image-box.component.html',
  styleUrls: ['./my-image-box.component.scss']
})
export class MyImageBoxComponent implements OnInit {
@Input() title:string="";
  constructor(public theme:ThemeService) { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-input-date-month',
  templateUrl: './my-input-date-month.component.html',
  styleUrls: ['./my-input-date-month.component.scss']
})
export class MyInputDateMonthComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

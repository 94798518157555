import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Common } from 'src/app/models/base/common.model';
import { SPOperationsService } from 'src/app/services/base/spoperations.service';

import * as customConfig from 'src/assets/customConfig.json';
import { UserService } from './user.service';
import { catchError, finalize } from 'rxjs/operators';
import { empty, throwError } from 'rxjs';


class User{
  Email:string = null;
  Id:number = null;
  Title:string = null;
  IsSiteAdmin:boolean = false;
}

@Injectable({
  providedIn: 'root'
})
export class CrmApiService {

  //readonly rootUrl: string = "https://sharepointapi.azure-api.net/bmr/crm";
  readonly rootUrl: string = customConfig.crmApi;

  common:Common;
  user:User = null;
  permission: string = "";

  constructor(
    public http: HttpClient,     
    public sp: SPOperationsService, 
    public userService:UserService 
   ) {
     this.common =  new Common();
  }  

  getByID(listName:string, ID:number , query:any) {
    var qry = this.common.queryBuilder("", query);
    return this.http.get(this.rootUrl + "/getbyid/manual/paths/invoke/" + listName + '/' + ID + '/' + qry, this.getHeaders());
  }

  

  getByQuery(listName: string, query: any) {
    var qryString = this.common.queryBuilder("", query);
    var obj = {
      "ListName": listName,
      "Query": qryString
    }
    return this.http.post(this.rootUrl + "/get/query/manual/paths/invoke", obj, this.getHeaders());
  }

  get(listName:string , query?:any) {
    var qry = this.common.queryBuilder("", query);
    return this.http.get(this.rootUrl + "/get/manual/paths/invoke/" + listName + '/' + qry, this.getHeaders());
  }

  getNext(listName: string, query: any) {    
    var obj = {
      "ListName": listName,
      "Query": query
    }
    return this.http.post(this.rootUrl + "/get/query/manual/paths/invoke", obj, this.getHeaders());
  }

  post(listName:string , jsonBody:any) {

    if(jsonBody['AuthorId'] == null)
      jsonBody['AuthorId'] = this.user.Id;

    if(jsonBody['EditorId'] == null)
      jsonBody['EditorId'] = this.user.Id;

    var obj = {
      "ListName":listName,
      "ListObject":this.sp.createItem(listName, jsonBody)
    }
    
    //console.log(obj);
    return this.http.post(this.rootUrl + "/post/manual/paths/invoke", obj, this.getHeaders());
  }

  put(listName:string, ID:number , jsonBody:any) {

    //if(jsonBody['EditorId'] == null)
      jsonBody['EditorId'] = this.user.Id;

    var obj = {
      "ID":ID,
      "ListName":listName,
      "ListObject":this.sp.createItem(listName, jsonBody)     
    }
    
    //console.log(obj);
    return this.http.post(this.rootUrl + "/put/manual/paths/invoke", obj, this.getHeaders());
  }

  async getUser() {
    if (this.userService.profile == null) {
      let profile = await this.userService.getProfile();
    }

    if(this.user != null)
      return;

    var obj = {
      "UserEmail": this.userService.profile.mail
    }
    
   await this.http.post(this.rootUrl + "/get/user/manual/paths/invoke", obj).pipe(
      catchError(error => {
        //error.status == 404
        //return empty;
        this.permission = "no-access";
        return throwError(error);
      }),
      finalize(() => {

        // Sameer:01/31/2022this.isLoading = false
      }),
    ).toPromise().then(async res => {
      this.user = res['d'];
      
     await this.getUserGroups().toPromise().then(res => {
        console.log("User Groups", res['d'].results);

        var groups = res['d'].results as any[];

        var owners = groups.filter(res => {
          return res.Title == "CRM Owners"
        })

        if (owners.length > 0) {
          this.permission = "owner"
        }
        else {
          var members = groups.filter(res => {
            return res.Title == "CRM Members"
          })

          if (members.length > 0) {
            this.permission = "member"
          } else {
            var visitors = groups.filter(res => {
              return res.Title == "CRM Visitors"
            })

            if (visitors.length > 0) {
              this.permission = "visitor"
            } else {
              this.permission = "no-access"
            }
          }

        }
      })
    },error =>{
      console.log("my error")
    });
  }

  CheckAcces(){
    if(this.permission != "" && this.permission != "no-access")
      return true

    return false;
  }

  //Check Permission
  CP():boolean{
    if(this.permission == "owner" || this.permission == "member")
      return true;
    
    return false;
  }

  getUserGroups() {  
    return this.http.get(this.rootUrl + "/get/user/groups/manual/paths/invoke/" + this.user.Id, this.getHeaders());
  }

  getHeaders(){
    const httpOptions = {
      headers: new HttpHeaders({                        
        'Ocp-Apim-Subscription-Key' : customConfig.azureSubscriptionKey,  
        
      })      
    };

    return httpOptions;
  }

  
}

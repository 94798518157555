import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { RouteService } from 'src/app/services/base/route.service';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthenticateService } from 'src/app/services/base/authenticate.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  @Input('drawer') drawer: MatSidenav;

  constructor(
    public routeSerice:RouteService,
    public router: Router,
    public authenticate:AuthenticateService
    ) { }

  ngOnInit(): void {
  }

  openHome(){
    this.drawer.close()
    this.router.navigate(['/app/home']);
  }

  logout() {    
    localStorage.setItem('freddMobileToken', '');
    this.authenticate.isLoggedIn = false;      
    this.router.navigate(['/login']);    
}

  
}

import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import * as customConfig from 'src/assets/customConfig.json';


 
@Injectable({ providedIn: 'root' })

export class AuthenticationService {
  
  constructor(
 ) {        
  }


  public CurrentUser(): any {
    let data = window.localStorage.getItem('cUser');
    if (data == null) return null;//data = JSON.stringify(new User());
     console.log(new BehaviorSubject<any>(JSON.parse(data)).value)
    return new BehaviorSubject<any>(JSON.parse(data)).value;
  }

  public login(user: any) {
    window.localStorage.setItem('cUser', JSON.stringify(user));    
    return user;
  }

  public redirectToLogin() {
 //   window.location.href = `${customConfig.instance}/${customConfig.domain}/oauth2/v2.0/authorize?p=${customConfig.signupSignInPolicy}&client_id=${customConfig.clientId}&nonce=defaultNonce&redirect_uri=${customConfig.callbackPathBase}/signin&scope=openid&response_type=id_token&prompt=login`;
    //var url = `${customConfig.authorityDomain}/${customConfig.domain}/oauth2/v2.0/authorize?p=${customConfig.signupSignInPolicy}&client_id=${customConfig.clientId}&redirect_uri=${customConfig.callbackPathBase}/resetpassword&scope=${customConfig.scope}&response_type=id_token&prompt=login`;
    var url = `https://bmrdevb2c.b2clogin.com/bmrdevb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_login&client_id=dcbd690a-7896-49e2-a9d1-fdd3d682a36c&nonce=defaultNonce&redirect_uri=${customConfig.redirectUri}&scope=openid&response_type=id_token&prompt=login`;
    //var url = `https://freddextranet.b2clogin.com/freddextranet.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_login&client_id=0a3c6b3d-fb40-4c9a-8c97-8fd09d6f7593&nonce=defaultNonce&redirect_uri=${customConfig.redirectUri}&scope=openid&response_type=id_token&prompt=login`;
    
    window.location.href  = url;
  }

  public logout() {    
    window.localStorage.removeItem('freddMobileToken');
    // remove user from local storage to log user out
    window.localStorage.removeItem('cUser');
    window.localStorage.removeItem('accountExpired');
    this.redirectToLogin();
  }





}



import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationService } from 'src/app/helper/authentication.service';
import { JwtHelper } from 'src/app/helper/jwt-helper';
import { UserService } from 'src/app/services/base/user.service';
import { UserServiceFredd } from 'src/app/modules/freddweb/services/user.service';
import * as customConfig from 'src/assets/customConfig.json';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  public onBoaringUrl: string = "";
  private readonly tokenIdentifier = "id_token=";
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private msalService: MsalService,
    private activatedRoute: ActivatedRoute,    
    private jwtHelper: JwtHelper,    
    private msalAuthService: MsalService,
    private userService: UserService,
    public datepipe:DatePipe,
    public freddUserService:UserServiceFredd,
    //private userManagerService:UserManagerService,    
    //public customersService:CustomersService,
    //public messageDialog: MessageDialogeService    
  ) { 
    
  }

  ngOnInit(): void {
    this.canActivate(this.activatedRoute.snapshot);
  }
  updateDate(){

    this.userService.getCurrecntUser().subscribe((res:any)=>{

      let item = res;

      item.lastDate = this.datepipe.transform(new Date(),'MM/dd/yyyy')

      this.freddUserService.put(item.id,item).subscribe((res)=>{

        // console.log("Updated S")

      })    })

  }
 async checkExpiry(){
   await this.userService.getCurrecntUser().subscribe((res:any)=>{
      var exirydate = this.datepipe.transform(res.expiryDate,'MM/dd/yyyy');
      
     var currentDate = this.datepipe.transform(new Date(),'MM/dd/yyyy');
     if(res.users_Admin.length == 0){

       if(exirydate < currentDate ){
        this.router.navigate(["authenticate"]);
        
        localStorage.setItem('accountExpired','false')
       }
       else{
        this.router.navigate([""]);
        localStorage.setItem('accountExpired','true');
        
      }
    }
    else{
      localStorage.setItem('accountExpired','true');
       this.router.navigate([""]);
       
     }
    })
  }
  canActivate(route: ActivatedRouteSnapshot) {
    const url = route.url[0].path;
   
    console.log(route);    
    
      var fragment = "";
      if (route.fragment)
          fragment = route.fragment;

      if (!fragment) {
          alert("Some thing went wrong. Please check inspect mode and contact to administrator")
          return;
      }

    

    if(url == "signin"){
      this.processSignin(fragment);
    } else if(url == "resetpassword"){
      this.processResetPassword(fragment);
    } else if(url == "register"){
      //this.processSignup(fragment);
    } else {
      //this.router.navigate([""]);         
    }

  }


  private async processSignin(routefragment: string) {
    const fragment = routefragment ? routefragment : "dummyfragment";
    const token = fragment.substring(this.tokenIdentifier.length, fragment.length);
    const decoded = this.jwtHelper.decodeToken(token);
    debugger;

   
    //if (decoded.tid) 
    {
      if (decoded) {
        var objUser: User = new User();
        objUser.Id = decoded.sub;
        objUser.Name = decoded.name;
        objUser.token = token;
        //objUser.tid = decoded.tid;
        if(decoded.emails)
          objUser.email = decoded.emails[0];
        else if(decoded.email)
          objUser.email = decoded.email;

        (await this.userService.UpdateUserAzureID(objUser.email, decoded.sub)).subscribe(res => {
          this.authService.login(objUser);
           this.checkExpiry();
           this.updateDate();
        })

      } else if (token && token.search("ess_denied&error_description=AADB2C90118") !== -1) {
        window.location.href = this.getResetPasswordUrl();
      } else {
        this.router.navigate([""]);
      }
    }
  }


  private processResetPassword(routefragment:string): boolean {
    const fragment = routefragment ? routefragment : "dummyfragment";
    const token = fragment.substring(this.tokenIdentifier.length, fragment.length);
    
    const decoded = this.jwtHelper.decodeToken(token);

    if (decoded) {
      if (!this.authService.CurrentUser) {
        this.msalAuthService.loginRedirect();        
        window.location.href = this.getResetPasswordUrl();;
      } else {        
        var objUser: User = new User();        
        objUser.Id = decoded.sub;        
        objUser.Name = decoded.name;
        objUser.token = token;
        //objUser.tid = decoded.tid;        
        objUser.email = decoded.email;
        this.authService.login(objUser);
        this.router.navigate([""]);
        return true;
      }
    } else {
      window.location.href = this.getResetPasswordUrl();
    }

    return false;
  }

  getResetPasswordUrl():string{

    var url = `${customConfig.instance}/${customConfig.domain}/oauth2/v2.0/authorize?p=${customConfig.passwordResetPolicy}&client_id=${customConfig.clientId}&redirect_uri=${customConfig.redirectUri}/resetpassword&scope=${customConfig.scopeopenid}&response_type=id_token&prompt=login`;

    return url

    //return "";

  }


//   private get sessionTimeout(): Date {
//     let sessionTimeout = new Date();
//     sessionTimeout.setMinutes(
//       sessionTimeout.getMinutes() + this.config.sessionTimeoutMins ? this.config.sessionTimeoutMins : 480
//     );
//     return sessionTimeout;
//   }
}


class User {
  Name:string = "";
  Id:string = "";  
  token:string = "";
  email:string = "";   
  tid:string = ""; 
}
<div class="row card-margin">
  <div class="col-md-12">

    <mat-selection-list  #forms [multiple]="false" infinite-scroll [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50" [infiniteScrollContainer]="'.mainScroll'" [fromRoot]="true"
      (scrolled)="onScrollingFinished()">
      <mat-list-option [disableRipple]="true" *ngFor="let d of searchList" [value]="d" (click)="onClick(d)">
        <div class="list-box">
          <img *ngIf="image" src="assets/img/building.jpg" alt="" class="myImg me-2">
          <div class="d-flex justify-space-between align-items-center visible-area w-100">
            <span class="flex-grow-1 bd-highlight titleFont" [class]="theme.myIconColor">{{getValue(d)}}</span>
            <button type="button" mat-icon-button *ngIf="canClick">
              <mat-icon [class]="theme.myIconColor" (click)="onIconClick(d)">{{icon}}</mat-icon>
            </button>
            <button type="button" mat-icon-button *ngIf="!canClick">
                <mat-icon style="color:transparent;" >{{icon}}</mat-icon>
          </button>
          </div>
        </div>
        <div class="d-flex flex-column heading w-100">
          <div *ngIf="val1" class="d-flex justify-space-between" style="justify-content: space-between">
            <span *ngIf="val1?.title">{{val1?.title}}</span>
            <span>{{val1?.type == 'currency' ? (getVal1(d) | currency) : val1?.type == 'number' ? (getVal1(d) | number) : val1?.type == 'date' ? (getVal1(d) | date : 'MM/dd/yyyy') : val1?.type == 'phone' ? (getVal1(d) | mask: '(000) 000-00000') : val1?.type == 'text' ? getVal1(d) : ''}}
            </span>
          </div>
          <div *ngIf="val2" class="d-flex justify-space-between" style="justify-content: space-between">
            <span *ngIf="val2?.title">{{val2?.title}}</span>
            <span>{{val2?.type == 'currency' ? (getVal2(d) | currency) : val2?.type == 'number' ? (getVal2(d) | number) : val2?.type == 'date' ? (getVal2(d) | date : 'MM/dd/yyyy') : val2?.type == 'phone' ? (getVal2(d) | mask: '(000) 000-00000') : val2?.type == 'text' ? getVal2(d) : ''}}
            </span>
          </div>
          <div *ngIf="val3" class="d-flex justify-space-between" style="justify-content: space-between">
            <span *ngIf="val3?.title"> {{val3?.title}}</span>
            <span>{{val3?.type == 'currency' ? (getVal3(d) | currency) : val3?.type == 'number' ? (getVal3(d) | number) : val3?.type == 'date' ? (getVal3(d) | date : 'MM/dd/yyyy') : val3?.type == 'phone' ? (getVal3(d) | mask: '(000) 000-00000') : val3?.type == 'text' ? getVal3(d) : ''}}
            </span>
          </div>
        </div>
        <div class="row mt-2" *ngIf="bottomButtonText">
          <div class="col-12">
            <button mat-button (click)="bottomBtnClick(d)" class="bottomButton" [class]="theme.myIconColor">
              <mat-icon [class]="theme.myIconColor" class="me-1">{{bottomButtonIcon}}</mat-icon> {{bottomButtonText}}
            </button>
          </div>
          <div class="col-9"></div>
        </div>
      </mat-list-option>
    </mat-selection-list>
    <br>
    <div class="row" *ngIf="isLoaded && searchList.length > 0 && expandMore">
      <div class="col-2"></div>
      <div class="col-8">
        <app-my-small-button title="More" icon="expand_more" (myClick)="moreBtnClick()"></app-my-small-button>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</div>


<!-- <ul class="multiselect-list"  
infinite-scroll 
[infiniteScrollDistance]="2"
[infiniteScrollThrottle]="50"
[infiniteScrollContainer]="'.mainScroll'"
[fromRoot]="true"
(scrolled)="onScrollingFinished()"
>
    <li style="padding-top: 20px;padding-bottom: 30px;" *ngFor="let d of searchList"  >      
      <label >{{getValue(d)}}</label>
    </li>
</ul> -->


<!-- <ul class="multiselect-list"  
infinite-scroll 
[infiniteScrollDistance]="2"
[infiniteScrollThrottle]="50"
(scrolled)="onScrollingFinished()"
[scrollWindow]="false"
>
    <li style="padding-top: 20px;padding-bottom: 30px;" *ngFor="let d of searchList"  >      
      <label >{{getValue(d)}}</label>
    </li>
</ul> -->
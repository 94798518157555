import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/services/base/base.service';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  constructor(private base: BaseService) { }

  getTenants(termGuid: string, isActive: boolean) {
    /*
    var query = {
      "TermGUID": termGuid,
      "ChildTermType": "Tenants"
    }
    return this.base.api.post("GetChildTerms", query);
    */

    var obj = {
      "filter": "{ TermGuid:'" + termGuid + "', ChildTermType:'Tenants', Action:'GetChildTerms', 'isActiveTenants': " + isActive + "  }"
    }

    return this.base.api.post("", obj);


  }



  getTenantDocuments(tentTermGuid) {
    /*
    var query = {
      "TermGUID": tentTermGuid
    }
    return this.base.api.post("GetChildTerms", query);
    */

   var obj = {
    "filter": "{ TermGuid:'" + tentTermGuid + "', Action:'GetChildTerms' }"
  }

  return this.base.api.post("", obj);
  }

  getDocument(market: string, property: string, tenant: string, documentType: string) {
    /*
    var query = {
      "Library": market,
      "Property": property,
      "Tenant": tenant,
      "DocumentType": documentType

    }
    return this.base.api.post("GetDocumentsByFilter", query);
    */

   var library = "";
   if(market == "East Coast" || market == "West Coast" || market == "Wexford")
     library = "Formerly Owned Properties";    
   else 
     library = market;

    var obj = {
      "filter": "{ Library:'" + library + "', Property:'" + property + "', DocumentType:'" + documentType + "', Tenant:'" + tenant + "', Action:'GetDocumentsByFilter' }"
    }

    return this.base.api.post("", obj);

  }


}

import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-mobile-box',
  templateUrl: './mobile-box.component.html',
  styleUrls: ['./mobile-box.component.scss']
})
export class MobileBoxComponent implements OnInit, OnChanges {
 
  @Input() heading:string = ""; 
  @Input() icon:string = "";
  @Input() loader:boolean = false;
  @Output() myClick =  new EventEmitter<any>();
  @Input() imgurl:string = "";

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
   console.log(this.loader)
  }

  onClick(){
    if(this.loader == false)
      this.myClick.emit();
  }

}

<div class="top-ribbon-dialog" [class]="theme.myTopNavColor">         
    <h5 style="font-size: 1rem;padding-left: 25px;">{{data?.data?.label}}</h5>
    <span class="spacer"></span>
    
    <button *ngIf="data?.data?.text" mat-button class="ribbon-button-padding" type="button" (click)="onClearClick()">            
            <span [class]="theme.myDialogIconBgColor">Clear</span>          
    </button>

    <app-ribbon-cancel-dialog mat-dialog-close></app-ribbon-cancel-dialog>
    
</div>
<!-- <app-my-heading-dialoge mat-dialog-title heading="Select Customer"></app-my-heading-dialoge> -->
<br><br>
<app-my-list 
    [search]="true"
    [data]="data?.data?.data" 
    [title]="data?.data?.title" 
    icon="navigate_next" 
    [val1]="data?.data?.val1"
    [val2]="data?.data?.val2"                
    [val2]="data?.data?.val3"                
    (myClick)="onListItemClick($event)">
</app-my-list> 
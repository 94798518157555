<div class="row desktop" style="margin-top: -4px;">
  <div class="col-md-7">
    <div class="d-flex bd-highlight" *ngIf="search">
     
      <div class="p-2 flex-grow-1"  >
        <!-- <mat-form-field appearance="outline" class="w-100" [style.fontSize.px]="12" autocomplete="off">
          <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input autocomplete="off">
        </mat-form-field > -->
        <app-my-table-search (change)="applyFilter($event)" *ngIf="!filter"></app-my-table-search>
      </div>
    </div>
  </div>
  <div class="col-md-5 " >
    <div class="d-flex bd-highlight justify-content-end">
      <div class="p-2 bd-highlight">        
        <button mat-raised-button style="margin-top: 7px;" (click)="onFilterClick()" class="shadow ms-1" >
          <mat-icon class="add-icon" [class]="theme.myIconColor">filter_alt</mat-icon> Filter
        </button>
        <button type="button" (click)="onAddClick()" mat-raised-button style="margin-top: 7px;" class="shadow btnAdd">
          <mat-icon class="add-icon" [class]="theme.myIconColor">add</mat-icon> Add
        </button>
      </div>      
    </div>
  </div>
</div>


<div class="desktop">
    <div class="table-responsive mat-elevation-z8 " style="margin-top: 10px; ">

        <table mat-table [dataSource]="data" matSort class="mat-elevation-z8 w-100">
          <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
      
            <th mat-header-cell *matHeaderCellDef>
              <div class=" pt-3 pb-1" mat-sort-header *ngIf="column.columnDef != 'action'">
                {{column.header}}
              </div>
              <app-my-table-filter *ngIf="filter && column.columnDef != 'action'" [hidden]="false" label="Filter" [data]="column.filterData" [title]="column.header"
                [name]="column.columnDef" [model]="column.filterValue" (change)="filterChange(column,$event)"
                class="w-100 mt-3 columnFilter">
              </app-my-table-filter>
            </th>
      
            <td mat-cell *matCellDef="let row" >
              <span *ngIf="column.columnDef != 'action'">
                {{ column.type == 'currency' ? (column.cell(row) | currency) : column.type == 'date' ?  (column.cell(row) | date:'MM/dd/yyyy') : column.cell(row) == 'null' ? '' : column.cell(row) }}
              </span>
              <span *ngIf="column.columnDef == 'action'">
                <div class="d-flex">
                    <button mat-icon-button matTooltip="Delete" (click)="onDeleteClick(row)"  *ngIf="delete">
                        <mat-icon class="btn-delete table-button" [class]="theme.myIconColor">delete_outline</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Edit" (click)="onEditClick(row)">
                        <mat-icon class="btn-edit table-button" [class]="theme.myIconColor">mode_edit_outline</mat-icon>
                    </button>
                    
                </div>           
              </span>
            </td>
            
          </ng-container>
      
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          
        </table>  
      </div>
      
      <mat-paginator class="shadow" *ngIf="paging" [pageSizeOptions]="[5, 10, 20]" [pageSize]="20" showFirstLastButtons
            aria-label="Select page of periodic elements">
      </mat-paginator>
</div>

<div class="mobile">
  <div class="row" *ngIf="add">
    <div class="col-12">
        <button type="button" (click)="onAddClick()" mat-icon-button class="topbtn">
            <mat-icon class="add-icon" style="color:white;">add</mat-icon>
        </button>
    </div>
      
  </div>
    
    <app-my-list 
    [search]="true"
    [data]="data.data" 
    [title]="listTitle" 
    [icon]="listIcon" 
    [val1]="listVal1"
    [val2]="listVal2"
    [isFullTab]="isFullTab"
    [val3]="listVal3"
    (myClick)="onListItemClick($event)"
    (bottomButtonClick)="bottomBtnClick()"
    [bottomButtonText]="bottomButtonText"
    [bottomButtonIcon]="bottomButtonIcon">
    </app-my-list>
</div>


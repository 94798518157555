import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-my-table-search',
  templateUrl: './my-table-search.component.html',
  styleUrls: ['./my-table-search.component.scss']
})
export class MyTableSearchComponent implements OnInit,AfterViewInit {
  
  @ViewChild('swiper') swiper: ElementRef;
  @Output() change = new EventEmitter<String>();

  constructor() { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    //this.swiper.nativeElement.focus();
  }

  onChange(event){
    this.change.emit( (event.target as HTMLInputElement).value);
  }
}

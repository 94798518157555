import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/base/dialog.service';

@Component({
  selector: 'app-yes-no',
  templateUrl: './yes-no.component.html',
  styleUrls: ['./yes-no.component.scss']
})
export class YesNoComponent implements OnInit {

  constructor(public dialogService:DialogService) { }

  ngOnInit(): void {
  }

  onClose(){
    this.dialogService.closeSmallDialog("yes");
  }

}

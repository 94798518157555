import { filter } from 'rxjs/operators';
// angular
import { Component, OnInit,  } from '@angular/core';

import { Router } from '@angular/router';
// Base
import { UserService } from 'src/app/services/base/user.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Customer Relationship Management';
  public searchInput = false;
  contactData: any[] = [];
  isLoading = false;
  errorMsg: string;
  userManualLink: string = "";
  feedBackLink: string = "";
  settlementTamplateLink: string = "";
  contractTamplateLink: string = "";
  siteContentLink: string = "";
  homepage: string = "";
  homepageTitle: string = "";
  fredd: string = "";
  freddTitle: string = "";
  userName:string = "";
  public AppLogoImg = "";

  constructor(
    public userService: UserService,
    public router: Router,
  ) {
    //this.common.getSectionData();

  }

  intervalId: any;


  ngOnInit() {
  }



}


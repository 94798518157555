import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ThemeService } from 'src/app/services/base/theme.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { MyListPopupComponent } from '../my-list-popup/my-list-popup.component';

@Component({
  selector: 'app-list-searh-button',
  templateUrl: './list-searh-button.component.html',
  styleUrls: ['./list-searh-button.component.scss']
})
export class ListSearhButtonComponent implements OnInit {

  @Input() data:any[]=[];
  @Input() valid:boolean = false;    
  @Input() icon:string = "";

  @Input() title:string = "";
  @Input() text:string = "";
  @Input() label:string = "";
  @Input() val1: any = null;
  @Input() val2: any = null;
  @Input() val3: any = null;

  @Output() myClick =  new EventEmitter();
  
  constructor(
    public theme:ThemeService,
    public dialogService:DialogService,) { }

  ngOnInit(): void {
  }

  
  onClick(){
    var obj ={
      data:this.data,
      title:this.title,
      label:this.label,
      icon:this.icon,      
      val1:this.val1,
      val2:this.val2,
      val3:this.val3,
    }
    this.dialogService.openDialog(MyListPopupComponent, null, obj);

    this.dialogService.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log("If results");        
        this.myClick.emit(result);
      } else {
        console.log("If not results");        
      }      
    });

   
  }
}

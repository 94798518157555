import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/services/base/base.service';
import { TableOfContents } from '../models/tableofcontents.model';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  TermGlobalData:any=[]
  Regions: any = [
    {
      Name: "East Coast",
      TermGuid: "0de5ab4f-18ce-4e15-903e-da1add799cac",
      Children: [
        {
          Name: "Massachusetts",
          TermGuid: "05f2e86d-0489-45c2-833f-dd06a41c5442",
          ParentGuid: "0de5ab4f-18ce-4e15-903e-da1add799cac",
          Children: [
            {
              Name: "20 Sidney Street Condominium",
              Children: [
                { Name: "Plaza Delly", TermGuid: "1e036c56-dde7-4ba8-b770-f874c37f4145", ParentGuid: "1e036c56-dde7-4ba8-b770-f874c37f4145" },
              ],
              TermGuid: "1e036c56-dde7-4ba8-b770-f874c37f4145", ParentGuid: "05f2e86d-0489-45c2-833f-dd06a41c5442"
            },
            { Name: "Albany", TermGuid: "cf718222-fe5f-4e6a-884d-373893a93ddd", ParentGuid: "05f2e86d-0489-45c2-833f-dd06a41c5442" },
            { Name: "Assembly-Phase 1", TermGuid: "34f58e12-2dec-409b-90fe-8c4bdd34d6f1", ParentGuid: "05f2e86d-0489-45c2-833f-dd06a41c5442" },

          ]
        },
        {
          Name: "New Jersey",
          TermGuid: "06fd1265-f6f8-45d9-8aee-935db05f701e",
          ParentGuid: "0de5ab4f-18ce-4e15-903e-da1add799cac",
          Children:[
            { Name: "new jersey 1", TermGuid: "46d4a6bc-7c59-4bcf-b0b6-5497fe496a06", ParentGuid: "06fd1265-f6f8-45d9-8aee-935db05f701e" },
            { Name: "new jersey 2", TermGuid: "ccaffc11-1004-4776-aa79-958ecb8643af", ParentGuid: "06fd1265-f6f8-45d9-8aee-935db05f701e" },
            { Name: "new jersey 3", TermGuid: "c2c40d5e-55af-4a48-8245-37ff288b34d3", ParentGuid: "06fd1265-f6f8-45d9-8aee-935db05f701e" },
          ]
        },
      ]
    }
  ];
  Child: any = [];

  markets: any[] = [
    { Name: "Massachusetts", TermGuid: "05f2e86d-0489-45c2-833f-dd06a41c5442", ParentGuid: "0de5ab4f-18ce-4e15-903e-da1add799cac" },
    { Name: "New Jersey", TermGuid: "06fd1265-f6f8-45d9-8aee-935db05f701e", ParentGuid: "0de5ab4f-18ce-4e15-903e-da1add799cac" },
  ];
  properties: any[] = [
    { Name: "20 Sidney Street Condominium", TermGuid: "1e036c56-dde7-4ba8-b770-f874c37f4145", ParentGuid: "05f2e86d-0489-45c2-833f-dd06a41c5442" },
    { Name: "Albany", TermGuid: "cf718222-fe5f-4e6a-884d-373893a93ddd", ParentGuid: "05f2e86d-0489-45c2-833f-dd06a41c5442" },
    { Name: "Assembly-Phase 1", TermGuid: "34f58e12-2dec-409b-90fe-8c4bdd34d6f1", ParentGuid: "05f2e86d-0489-45c2-833f-dd06a41c5442" },
    { Name: "new jersey 1", TermGuid: "46d4a6bc-7c59-4bcf-b0b6-5497fe496a06", ParentGuid: "06fd1265-f6f8-45d9-8aee-935db05f701e" },
    { Name: "new jersey 2", TermGuid: "ccaffc11-1004-4776-aa79-958ecb8643af", ParentGuid: "06fd1265-f6f8-45d9-8aee-935db05f701e" },
    { Name: "new jersey 3", TermGuid: "c2c40d5e-55af-4a48-8245-37ff288b34d3", ParentGuid: "06fd1265-f6f8-45d9-8aee-935db05f701e" },
  ];
  tenants: any[] = [
    { Name: "Plaza Delly", TermGuid: "1e036c56-dde7-4ba8-b770-f874c37f4145", ParentGuid: "1e036c56-dde7-4ba8-b770-f874c37f4145" },
  ]
  constructor(private base: BaseService) { }




  getTenants(termGuid: string, isActive: boolean) {
    /*
    var query = {
      "TermGUID": termGuid,
      "ChildTermType": "Tenants"
    }
    return this.base.api.post("GetChildTerms", query);
    */

    var obj = {
      "filter": "{ TermGuid:'" + termGuid + "', ChildTermType:'Tenants', Action:'GetChildTerms', 'isActiveTenants': " + isActive + "  }"
    }

    return this.base.api.post("", obj);


  }
  getGlobalTerm(termGuid){
    var obj = {
      "filter": "{ TermGuid:'" + termGuid + "', Action:'GetTermStore' }"
    }

    return this.base.api.post("", obj);
  }
  tableOfContents: TableOfContents[] = [];

  async getDocuments() {
    await this.getTenantDocuments(
      'e090a814-a266-41b7-b889-425f2f90a985'
    ).subscribe(
      (res) => {
        this.Regions = res; //
        this.tableOfContents = [];
        for (let index = 0; index < this.Regions.length; index++) {
          if (index == 2) {

            const element = res[index];
            element['selected'] = false
            element['index'] = index;
            element['action'] = 'close';
            element['parent'] = null;
            element['children'] = [];
            this.getChild(element, element.TermGuid);
            this.tableOfContents.push(element);
          }
        };

      },
      (error) => {
        console.log(error);
      }
    );
  }
  async getChild(row: TableOfContents, value2) {
    if (row.action == 'close')
      row.action = 'open';
    else
      row.action = 'close';

    if (row.Children.length == 0) {
      this.getTenantDocuments(value2).subscribe((res: any) => {
        this.Child = res; //
        if (row.parent == null) {
          res.forEach(element => {
            this.markets.push(element);
          });
        }
        else {
          res.forEach(element => {
            this.properties.push(element);
          });
        }
        for (let index = 0; index < this.Child.length; index++) {
          const element = res[index];
          element['selected'] = row.selected;
          element['index'] = index;
          element['action'] = 'close';
          element['children'] = [];
          element['parent'] = row.Name;
          row.Children.push(element);
          // this.hasChild()
          //   this.dataSource.data = [row];
          //   (this.dataSource.data).forEach((key:any) => {
          //     this.setParent(key, null);
          // });
          this.getChild(element, element.TermGuid);

        }

      })
    }

  }

  getTenantDocuments(tentTermGuid) {
    /*
    var query = {
      "TermGUID": tentTermGuid
    }
    return this.base.api.post("GetChildTerms", query);
    */
    console.log('Regions', this.Regions)
    console.log('Markets', this.markets)
    console.log('Proper', this.properties)

    var obj = {
      "filter": "{ TermGuid:'" + tentTermGuid + "', Action:'GetChildTerms' }"
    }

    return this.base.api.post("", obj);
  }

  getDocument(market: string, property: string, tenant: string, documentType: string) {
    /*
    var query = {
      "Library": market,
      "Property": property,
      "Tenant": tenant,
      "DocumentType": documentType

    }
    return this.base.api.post("GetDocumentsByFilter", query);
    */

    var library = "";
    if (market == "East Coast" || market == "West Coast" || market == "Wexford")
      library = "Formerly Owned Properties";
    else
      library = market;

    var obj = {
      "filter": "{ Library:'" + library + "', Property:'" + property + "', DocumentType:'" + documentType + "', Tenant:'" + tenant + "', Action:'GetDocumentsByFilter' }"
    }

    return this.base.api.post("", obj);

  }


}

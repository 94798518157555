import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/helper/authentication.service';

@Component({
  selector: 'app-authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss']
})
export class AuthenticateComponent implements OnInit {

  constructor(public authentication:AuthenticationService) { }

  ngOnInit(): void {
  }
  logOut(){
    this.authentication.logout()
  }
}

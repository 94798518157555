import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SPOperationsService {

  constructor() {

  }
  
  setListItemType(listTitle: string) {
    let titleForItemTpe = listTitle.replace(/ /g, '_x0020_');
    return "SP.Data." + titleForItemTpe.charAt(0).toUpperCase() + titleForItemTpe.slice(1) + "ListItem";
  }
  

  createItem(listTitle: string, jsonBody: any) {
    
    const type = this.setListItemType(listTitle);

    if (!jsonBody.__metadata) {
      jsonBody.__metadata = {
        'type': type
      };
    }

    //const data = JSON.stringify(jsonBody);
    const data = jsonBody;
   
    return data;
  }

  /*
  createSPItem(listTitle: string, jsonBody: any) {
    const url = this.getReadURL(listTitle, null);
    const type = this.setListItemType(listTitle);

    if (!jsonBody.__metadata) {
      jsonBody.__metadata = {
        'type': type
      };
    }

    const data = JSON.stringify(jsonBody);
    return this.http.post(url, data, this.getHeaders(true, true))

  }

  async updateItem(listTitle: string, id: any, jsonBody: any) {
    const localOptions = this.getHeaders(true, false);
    localOptions['X-HTTP-Method'] = 'MERGE';
    localOptions['If-Match'] = '*';
    const type = this.setListItemType(listTitle);

    if (!jsonBody.__metadata) {
      jsonBody.__metadata = {
        'type': type
      };
    }

    const data = JSON.stringify(jsonBody);
    const url = this.getItemURL(listTitle, id);
    const httpOptions = {
      headers: new HttpHeaders(localOptions)
    };

    await this.http.post(url, data, httpOptions).toPromise().catch((err: HttpErrorResponse) => {
      const error = err.error;
      return error;
    });
  }
  */

  
}


import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
//import { AuthenticateService } from './authenticate.service';
import { AuthenticationService } from 'src/app/helper/authentication.service';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    public router: Router, 
    public authenticationService: AuthenticationService,
    public routeService: RouteService,
    //public route: ActivatedRoute,
    ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if (this.authenticationService.CurrentUser() == null){
        this.authenticationService.redirectToLogin();
        return false;
        //this.authenticate.isLoggedIn = false;                
      }
      // else {        
      //   return true;      
      // }
      // return true;

    /*
    var token = localStorage.getItem("freddMobileToken");
    if (token == "" || token == null || token == undefined) {
      this.authenticate.isLoggedIn = false;
      //this.router.navigate(["/app/home"]);
      this.router.navigate(["/login"]);
    }
    else {
      this.authenticate.isLoggedIn = true;
      //this.setAppName();
      if(route.data && route.data.appName){
        this.routeService.subApp.title  = route.data.appName;
        this.routeService.setSubApp(route.data.appName);
      }

      return true;
    }
    */
    

   if(route.data && route.data.appName){
    this.routeService.subApp.title  = route.data.appName;
    this.routeService.setSubApp(route.data.appName);
    }

    

    //this.authenticate.isLoggedIn = true; Sameer :02/10/2022
    return true;
    

    return false;
  }

  setAppName() {
    //this.routeService.subApp.title  = this.route.data['value'].appName;
  }


}

<div class="example-ripple-container mat-elevation-z4" [class]="theme.myHeadingColor" (click)="onClick()"
    matRipple
    [matRippleCentered]="centered"
    [matRippleDisabled]="disabled"
    [matRippleUnbounded]="unbounded"
    [matRippleRadius]="radius"
    [matRippleColor]="color">            
    <mat-icon class="icon-size">{{icon}}</mat-icon>
    <h2>{{title}}</h2>
</div>


import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-my-list-search',
  templateUrl: './my-list-search.component.html',
  styleUrls: ['./my-list-search.component.scss']
})
export class MyListSearchComponent implements  OnInit,AfterViewInit {
 
  
  @ViewChild('swiper') swiper: ElementRef;
  @Output() change = new EventEmitter<String>();
  
  value:string = "";

  constructor() { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    //this.swiper.nativeElement.focus();
  }

  onClear(){
    this.swiper.nativeElement.value = '';
    //this.value = '';
    this.change.emit( (event.target as HTMLInputElement).value);
  }

  onChange(event){
    
    this.change.emit( (event.target as HTMLInputElement).value);
  }
}


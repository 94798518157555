<mat-drawer-container class="example-container" hasBackdrop="true">
        <mat-drawer #drawer mode="over" class="sidenav" [class]="theme.myLeftNavColor">

        </mat-drawer>
        <mat-drawer-content class="mainScroll">

            <app-topnav 
            appName="FREDD Extranet"             
            (menuClick)="onAppClick()"
            (appClick)="onAppClick()"
            (subAppClick)="onSubAppClick(routeService?.subApp?.route)"
            (logoutClick)="logout()"            
            class="fixed-top">
            </app-topnav>
          
            <div class="container-fluid ">
                <router-outlet></router-outlet>
            </div>
        </mat-drawer-content>
</mat-drawer-container>


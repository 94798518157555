<div class="container-fluid">
    <div class="row content">
        <mat-sidenav-container class="example-container">
            <mat-sidenav class="treeview" #sidenav mode="side" style="width:18%;    height: 70%;" opened="">
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree"
                    style="margin-top: 10px;width: 200%;padding: 10px;">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                        <button class="btn-node" (click)="getPropertyDocuments(dataSource,node)">
                            <span>
                                {{node.metadata}}
                            </span>
                        </button>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <div class="mat-tree-node">
                            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.metadata">
                                <mat-icon class="mat-icon-rtl-mirror">
                                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                            </button>
                            {{node.metadata}}
                        </div>
                        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
                            <ng-container matTreeNodeOutlet></ng-container>
                        </div>
                    </mat-nested-tree-node>
                </mat-tree>
            </mat-sidenav>

            <mat-sidenav-content>
                <div class="row ms-2 me-2">
                    <div class="row">
                        <div class="col-md-2" style="padding-top: 11px;">
                            <button mat-icon-button (click)="sidenav.toggle()" align="left">
                                <mat-icon>menu</mat-icon>
                            </button>
                            <h2 style="display: inline-block;">Documents</h2>
                        </div>
                        <div class="col-md-6"></div>
                        <div class="col-md-4">
                            <mat-form-field appearance="ouline" style="width: 100%;">
                                <mat-label> Search </mat-label>
                                <input matInput (keyup)="search($event)" placeholder="" #input>
                                <button *ngIf="input.value" matSuffix mat-icon-button aria-label="Clear">
                                    <mat-icon (click)="input.value='';search(input)">close</mat-icon>
                                </button>
                                <mat-icon *ngIf="!input.value" matSuffix style="color: #164287;">search</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row fst-italic" *ngIf="breadcrum">
                        <div class="col-md-12 ms-2">{{breadcrum}}</div>
                    </div>
                    <div class="mat-elevation-z8 mt-2">
                        <table mat-table [dataSource]="dataSourcetable" matSort>

                            <ng-container matColumnDef="Name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Document Name</th>
                                <td mat-cell *matCellDef="let row">
                                    <mat-icon style="color: red; cursor: pointer;" (click)="opendialog(row)">
                                        photo_filter</mat-icon> <a
                                        style=" text-decoration-line: underline; cursor: pointer;"
                                        (click)="opendialog(row)"> {{row.Name}}</a>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="DocumentDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Document Date </th>
                                <td mat-cell *matCellDef="let row"> {{row.DocumentDate }} </td>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">No data matching the filter {{input.value}}</td>
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[25, 5, 10, 100]" showFirstLastButtons
                            aria-label="Select page of users"></mat-paginator>
                    </div>
                </div>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
</div>
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  colorName:string = "darkblue";
  colorNameOption2:string = "blue";

 //#region
  myTopNavColor = "";
  myLeftNavColor = "";
  myHeadingColor = "";
  myIconColor = "";
  myIconBgColor = "";
  myButtonBgColor = "";
  myDialogIconBgColor = "";
  myTitleColor = "";
  //#endregion
  
  constructor(    
  ) { 
    this.myTopNavColor = "my-bg-top-heading-" + this.colorName;
    this.myLeftNavColor = "my-bg-left-nav-" + this.colorName;
    this.myHeadingColor = "my-form-heading-" + this.colorName;
    this.myIconColor = "my-icon-color-" + this.colorName;
    this.myIconBgColor = "my-icon-bg-color-" + this.colorName;
    this.myButtonBgColor = "my-bg-button-" + this.colorName;
    this.myDialogIconBgColor = "my-icon-dialog-bg-color-" + this.colorName;
    this.myTitleColor = "my-icon-color-" + this.colorNameOption2;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TenantService } from 'src/app/modules/freddweb/services/tenant.service';
import { UserService } from 'src/app/services/base/user.service';

@Component({
  selector: 'app-admin-portal',
  templateUrl: './admin-portal.component.html',
  styleUrls: ['./admin-portal.component.scss']
})
export class AdminPortalComponent implements OnInit {

  constructor(
    public userService : UserService,
    public router :Router,
    public Tenantservice: TenantService,
  ) { }

  ngOnInit(): void {
    var item = localStorage.getItem('accountExpired');
    if (item == "false") {
      this.router.navigate(['authenticate']);
    }
    //this.getGlobalTermStore()
  }

  getGlobalTermStore() {
    //this.initialize(TREE_DATA);
     if(this.Tenantservice.TermGlobalData.length == 0){
       this.Tenantservice.getGlobalTerm(
         'e090a814-a266-41b7-b889-425f2f90a985'
       ).subscribe((res) => {
         this.Tenantservice.TermGlobalData = res[0].Children;

       });
     }

  }

}

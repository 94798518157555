import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from 'src/app/helper/authentication.service';
import { BaseService } from 'src/app/services/base/base.service';
import * as customConfig from 'src/assets/customConfig.json';

@Injectable({
  providedIn: 'root'
})
export class MarketService {
  readonly rootUrl: string = customConfig.cSharpApi;
  dataEast: any[] = [];
  dataWest: any[] = [];
  dataForeign: any[] = [];
  dataFOP: any[] = [];
  constructor(private base: BaseService, public http: HttpClient, private authService: AuthenticationService) { }

  getAll(region: string) {
    /*
    var query = {
      "Library": "FREDD Libraries",
      "Region":region
    }
    
    return  this.base.api.post("GetLibrariesByRegion", query);
    */

    var obj = {
      "filter": "{ Library:'FREDD Libraries', Region: '" + region + "', Action:'GetLibrariesByRegion'}"
    }

    return this.base.api.post("", obj);
  }

   getUserById() {
    if(this.authService.CurrentUser()==null || this.authService.CurrentUser()==undefined){
      return
    }
    var userDetails = this.authService.CurrentUser().Id;
   return this.http.get(this.rootUrl + 'Users/GetUserWithAzureID/' + userDetails)
  }
  getAllTreeData() {
    if(this.authService.CurrentUser()==null || this.authService.CurrentUser()==undefined){
      return
    }
    var userId = this.authService.CurrentUser().Id;
    return this.http.get(this.rootUrl + "UserPermissions/GetPermissionsByUser/"+userId)

  }
  

}

<div class="row card-margin">
    <div class="col-md-12 content-area">       
        <app-my-small-button icon="close" mat-dialog-close></app-my-small-button>
        <br>
        <mat-selection-list #forms [multiple]="false">
            <mat-list-option>
                <div class="list-box">                        
                    <div class="d-flex justify-space-between align-items-center visible-area w-100" (click)="onClick('edit')">
                        <mat-icon class="me-1">edit</mat-icon>         
                        <span class="flex-grow-1 bd-highlight">Edit</span>                            
                                           
                    </div>                         
                </div>
            </mat-list-option>
            <mat-list-option>
                    <div class="list-box">                        
                        <div class="d-flex justify-space-between align-items-center visible-area w-100" (click)="onClick('delete')">
                            <mat-icon class="me-1">delete</mat-icon>  
                            <span class="flex-grow-1 bd-highlight">Delete</span>                            
                                                      
                        </div>                         
                    </div>
            </mat-list-option>
        </mat-selection-list>
    </div>
</div>
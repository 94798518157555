<div class="row card-margin">
        <div class="col-md-12 content-area">       
            <app-my-small-button icon="close" mat-dialog-close></app-my-small-button>
            <br>
            <mat-selection-list #forms [multiple]="false">
                <mat-list-option>
                    <div class="list-box">                        
                        <div class="d-flex justify-space-between align-items-center visible-area w-100" (click)="onClick('add')">
                            <mat-icon class="me-1">add</mat-icon>         
                            <span class="flex-grow-1 bd-highlight">Add</span>                            
                                               
                        </div>                         
                    </div>
                </mat-list-option>
                <mat-list-option>
                        <div class="list-box">                        
                            <div class="d-flex justify-space-between align-items-center visible-area w-100" (click)="onClick('view')">
                                <mat-icon class="me-1">visibility</mat-icon>  
                                <span class="flex-grow-1 bd-highlight">View</span>                            
                                                          
                            </div>                         
                        </div>
                </mat-list-option>
            </mat-selection-list>
        </div>
</div>
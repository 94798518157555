import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-my-input-date-range',
  templateUrl: './my-input-date-range.component.html',
  styleUrls: ['./my-input-date-range.component.scss']
})
export class MyInputDateRangeComponent implements OnInit, ControlValueAccessor  {

  startDate:string="";
  endDate:string="";
  obj:any= null;
  @Input() model: any = null;
  @Output() valueChanged = new EventEmitter<any>();
  
  constructor() { }

  
  writeValue(obj: any): void {
    if (obj !== undefined)
      this.model = obj;
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error("Method not implemented.");
  }

  ngOnInit(): void {
  }

  onChange(value: any) {
    this.propagateChange(value);
    if (this.startDate != '' && this.endDate != ''
      && this.startDate != null && this.endDate != null
      && this.startDate != undefined && this.endDate != undefined) {
        this.obj = {
          startDate:this.startDate,
          endDate:this.endDate,
        }
      this.valueChanged.emit(this.obj);
    }
      
  }

}

import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/services/base/base.service';

import * as customConfig from 'src/assets/customConfig.json';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackbarService } from 'src/app/services/base/snackbar.service';
import { DialogService } from 'src/app/services/base/dialog.service';

import { Router, ActivatedRoute } from '@angular/router';
import { PdfViewComponent } from '../../fredd/components/pdf-view/pdf-view.component';
import { AuthenticationService } from 'src/app/helper/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  myfile:string = "";
  properties:any[]=[];
  searchResults: any[] = [];

  constructor(
    private base: BaseService,
    private spinner: NgxSpinnerService,
    private snackBar:SnackbarService,
    public authService:AuthenticationService,
    public dialogService: DialogService,
    public router:Router,
    public route:ActivatedRoute,) { }

  getProperties(termGuid: string) {
    /*
    var query = {
      "TermGUID": termGuid,
      "ChildTermType": ""
    }
    return this.base.api.post("GetChildTerms", query);
    */

    var obj = {
      "filter": "{ TermGuid:'" + termGuid + "', Action:'GetChildTerms' }"
    }
  
    return  this.base.api.post("", obj);
  }

  async getAllProperties(){

    // if(this.properties.length > 0)
    //   return;

    var obj = {
      "filter": "{ 'Library':'FREDD Libraries', 'Action':'GetAllProperties' }"
    }
  
    await this.base.api.post("", obj).toPromise().then(res =>{
      this.properties = [];
      this.properties =  res as any[];
      console.log(this.properties)
    });  
  }

  getPropertyDocuments(termGuid: string) {
    /*
    var query = {
      "TermGUID": termGuid,                                                                                                      
    return this.base.api.post("GetChildTerms", query);
    */

   var obj = {
     "filter": "{ TermGuid:'" + termGuid + "', ChildTermType:'PropertyDocuments', Action:'GetChildTerms' }"
  }

    return  this.base.api.post("", obj);
  }

  getDocuments(market: string, property: string, documentType: string, Tenant:string = '') {
    var userId = this.authService.CurrentUser().Id;
    //{ Library:'Massachusetts', MetadataPath:'East Coast:Massachusetts:Blackfan:Accounting Correspondence', AzureUserId:'14041ce0-e63f-4b0d-beab-ac089e6e2980', Action:'GetFreddExtranetDocuments' }
    var library = "";
    if(market == "East Coast" || market == "West Coast" || market == "Wexford")
      library = "Formerly Owned Properties";    
    else 
      library = market;

    var obj = {
      "filter": "{ Library:'" + library + "', MetadataPath:'" + property + "',AzureUserId:'" +userId+"', Action:'GetFreddExtranetDocuments',IsAdmin:true  }"
    }
    return  this.base.api.post("", obj);
  }

  showPDF(file:string, filename:string){
    var obj = {
      pdfSrc:file,
      documentName: filename
    }
    this.dialogService.openDialog(PdfViewComponent, null, obj);

    this.dialogService.dialogRef.afterClosed().subscribe(result => {
      if(result){

      }
    });
  }

  
  fetch(query: string, fileURL: any, filename: string) {
    var url = customConfig.freddDocumentApi;
    this.spinner.show();
    // fetch(this.rootUrl + query, {
    fetch(url, {
      body: JSON.stringify({ "FileURL": fileURL }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Ocp-Apim-Subscription-Key':customConfig.azureSubscriptionKey
      },
      }).then(res => res.blob())
      .then(blob => {
          this.spinner.hide();
         
          this.myfile = window.URL.createObjectURL(blob);
          //this.router.navigate(['/app/fredd/pdf-view'])
          
          this.showPDF(this.myfile, filename)
          
          // window.location.assign(file);
          //window.open(file,"_blank");
          
          //this.snackBar.openSnackBar("Document downloaded successfully!");
      });

    // .then(res => res.blob()).then(res => {
    //   const blob = new Blob([res], { type: 'application/pdf' });
    //   const downloadUrl = URL.createObjectURL(blob);
      
    //   console.log('File Path',downloadUrl);

    //   this.spinner.hide();
    //   const a = document.createElement("a");
    //   a.href = downloadUrl;
    //   a.download = filename;
    //   document.body.appendChild(a);
    //   a.click();
    //   this.snackBar.openSnackBar("Document downloaded successfully!");
      
    // })

    //return this.http.post(this.rootUrl + controller, jsonBody, this.getPostHeaders());


    
  }



  
  downloadDocument(fileUrl:string, fileName:string){        
    this.base.api.fetch("GetDownloadedDocument", fileUrl, fileName);    
  }

  async getSearchResults(searchParameters: any) {
    var obj = {
      "filter": "{ 'Action':'GetSearchResults', 'SearchKeyword':'" + searchParameters.searchKeyword + "', 'LibraryToSearch':'" + searchParameters.libraryToSearch + "', 'SearchType':'" + searchParameters.searchType + "' }"
    }

    await this.base.api.post("", obj).toPromise().then(res => {
      this.searchResults = res as any[];
      console.log('Property Service - Search Results:', this.searchResults)
    });

    //var results = await this.base.api.post("", obj);
    return this.searchResults;
  }

  async getPropertySearchResults(searchParameters: any) {
    var obj = {
      "filter": "{ 'Action':'GetSearchResults', 'SearchKeyword':'" + searchParameters.searchKeyword + "', 'LibraryToSearch':'" + searchParameters.libraryToSearch + "', 'SearchType':'" + searchParameters.searchType + "', 'PropertyToFilter':'" + searchParameters.propertyToFilter + "' }"
    }

    await this.base.api.post("", obj).toPromise().then(res => {
      this.searchResults = res as any[];
      console.log('Property Service - Search Results:', this.searchResults)
    });

    //var results = await this.base.api.post("", obj);
    return this.searchResults;
  }
  



}

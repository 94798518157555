import { Component, OnInit, Input, Output, EventEmitter, forwardRef, OnChanges } from '@angular/core';
import { ThemeService } from 'src/app/services/base/theme.service';
import { DialogService } from 'src/app/services/base/dialog.service';
import { MyListPopupComponent } from '../my-list-popup/my-list-popup.component';
import { NgForm, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MyInputComponent } from '../../input/my-input/my-input.component';


@Component({
  selector: 'app-list-button-required',
  templateUrl: './list-button-required.component.html',
  styleUrls: ['./list-button-required.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ListButtonRequiredComponent),
      multi: true
    }
  ]
})
export class ListButtonRequiredComponent implements OnInit, OnChanges {
  

  @Input() data:any[]=[];
  @Input() valid:boolean = false;    
  @Input() icon:string = "";

  @Input() title:string = "";
  @Input() text:string = "";
  @Input() label:string = "";
  @Input() val1: any = null;
  @Input() val2: any = null;
  @Input() val3: any = null;

  @Output() myClick =  new EventEmitter();

  @Input() required:boolean = false;
  
  //For Input
  @Input() model: any = null;
  @Input() name: string = "";
  @Output() modelChange = new EventEmitter<any>();
  
  constructor(
    public theme:ThemeService,
    public dialogService:DialogService,) { }

  ngOnInit(): void {
  }

  showError:boolean = false;
  
  onClick(){
    var obj ={
      data:this.data,
      title:this.title,
      text:this.text,
      label:this.label,
      icon:this.icon,      
      val1:this.val1,
      val2:this.val2,
      val3:this.val3,
    }
    this.dialogService.openDialog(MyListPopupComponent, null, obj);

    this.dialogService.dialogRef.afterClosed().subscribe(result => {
      if (result) {        
        console.log("If results");        
       
        if(result == 'clear')
          {
            this.model = null;
            this.showError = true;
          }          
        else
          {
            this.model = result;
            this.showError = false;
          }
          
        this.myClick.emit(result);
      } else {        
        console.log("If not results");        
        this.model = null;
        this.showError = true;
      }      
    });   
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    // if(this.model == null)
    //   this.showError = true;
    // else
    //   this.showError = false;
  }

  //For Input

  writeValue(obj: any): void {
    if (obj !== undefined)
      this.model = obj;
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error("Method not implemented.");
  }
  
  onChange(value: any) {
    this.propagateChange(value);    
  }

}

<div class="row" style="margin-top: 10%; margin-left: 15px;">
    <div class="col-sm-4"></div>

    <div class="col-sm-2">
        <div class="card">
            <div class="card-header">
                Users
            </div>
            <img class="card-img-top" src="/assets/img/users.jpg" alt="Card image cap" height="220px" width="80px">
            <div class="card-body">
                <hr style="color: grey;">
                <div class="row">
                    <div class="col-sm-6">
                        <a routerLink="/app/home/admin"> <button mat-raised-button style="width: 110%;">
                                <mat-icon>add</mat-icon> Add
                            </button></a>
                    </div>
                    <div class="col-sm-6">
                        <a routerLink="/app/home/admin/list"> <button mat-raised-button style=" width: 110%;">
                                <mat-icon style="font-size: 20px;">list</mat-icon>View
                            </button> </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-2">
        <div class="card">
            <div class="card-header">
                Groups
            </div>
            <img class="card-img-top" src="/assets/img/group.jpg" alt="Card image cap" height="220px" width="80px">
            <div class="card-body">
                <hr style="color: grey;">
                <div class="row">
                    <div class="col-sm-6">
                        <a routerLink="/app/home/group"> <button mat-raised-button style="width: 110%;">
                                <mat-icon>add</mat-icon>Add
                            </button>
                        </a>
                    </div>
                    <div class="col-sm-6">
                        <a routerLink="/app/home/group/list"> <button mat-raised-button style="width: 110%;">
                                <mat-icon style="font-size: 20px;">list</mat-icon>View
                            </button></a>

                    </div>



                </div>

            </div>

        </div>
    </div>
</div>
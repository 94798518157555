import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  
  subApp:any = {title:"", route:""};


  freddRoutes: any[] = [
    {
      route: "/app/fredd/region",
      name: "regions",
      label: "Regions",
      type: "list"
    },
    {
      route: "/app/fredd/market",
      name: "markets",
      label: "Markets",
      type: "list"
    },
    {
      route: "/app/fredd/market-type",
      name: "marketTypes",
      label: "Market Types",
      type: "form"
    },
    {
      route: "/app/fredd/property-document-type",
      name: "documentTypes",
      label: "Document Types",
      type: "form"
    },
    {
      route: "/app/fredd/property-document",
      name: "documents",
      label: "Documents",
      type: "list"
    },    
    {
      route: "/app/fredd/tenant-document-type",
      name: "tenantTypes",
      label: "Tenant Types",
      type: "list"
    },
  ]


  CRMRoutes: any[] = [
    {
      route: "/app/crm/home",
      name: "home",
      label: "CRM",
      type: "form"
    },
    {
      route: "/app/crm/contact/list",
      name: "contactlist",
      label: "Contacts",
      type: "list"
    },
    {
      route: "/app/crm/category/list",
      name: "categorylist",
      label: "Categories",
      type: "list"
    },
    {
      route: "/app/crm/views",
      name: "views",
      label: "Views",
      type: "form"
    },    
    {
      route: "/app/crm/category/views",
      name: "categoryviews",
      label: "Views",
      type: "form"
    },
    {
      route: "/app/crm/contact/view",
      name: "contactview",
      label: "Contact View",
      type: "form"
    },
    
  ]

  BFSRoutes: any[] = [
    {
      route: "/app/bfs/home",
      name: "home",
      label: "BFS",
      type: "form"
    },
    {
      route: "/app/bfs/main/list",
      name: "bfslist",
      label: "Dashboard",
      type: "list"
    },{
      route: "/app/bfs/main/form",
      name: "bfsform",
      label: "Form",
      type: "form"
    },
    {
      route: "/app/bfs/main/view",
      name: "bfsview",
      label: "Dashboard",
      type: "form"
    },{
      route: "/app/bfs/main/filterview",
      name: "bfsviewFilters",
      label: "Filters",
      type: "form"
    },

    
  ]
  constructor() { }

  openRoute(module: string, name: string) {
    if (module == "fredd") {
      
      this.setSubApp("FREDD");
      var index = this.freddRoutes.findIndex(a => a.name == name);
      //this.subApp.title = "FREDD";
      //var subIndex = this.freddRoutes.findIndex(a => a.name == "regions");
      //this.subApp.route = this.freddRoutes[subIndex].route;
      return this.freddRoutes[index].route;
    } else if (module == "crm") {

      this.setSubApp("CRM");      
      var index = this.CRMRoutes.findIndex(a => a.name == name);
      /*
      this.subApp.title = "CRM";
      var subIndex = this.CRMRoutes.findIndex(a => a.name == "contactlist");
      this.subApp.route = this.CRMRoutes[subIndex].route;
      */
      return this.CRMRoutes[index].route;
      
    } else if (module == "bfs") {
      
      this.setSubApp("BFS");
      var index = this.BFSRoutes.findIndex(a => a.name == name);
      /*
      this.subApp.title = "BFS";
      var subIndex = this.BFSRoutes.findIndex(a => a.name == "bfslist");
      this.subApp.route = this.BFSRoutes[subIndex].route;
      */
      return this.BFSRoutes[index].route;
      
    }


  }

  setSubApp(subAppName:string){
    
    this.subApp.title = subAppName;

    if (subAppName == "CRM") {      
      var subIndex = this.CRMRoutes.findIndex(a => a.name == "contactlist");
      this.subApp.route = this.CRMRoutes[subIndex].route;      
    } else if (subAppName == "FREDD") {      
      var subIndex = this.freddRoutes.findIndex(a => a.name == "regions");
      this.subApp.route = this.freddRoutes[subIndex].route;      
    } else if (subAppName == "BFS") {      
      var subIndex = this.BFSRoutes.findIndex(a => a.name == "bfslist");
      this.subApp.route = this.BFSRoutes[subIndex].route;      
    }    
  }

  getRouteByType(module:string, type:string){
    if(module == "fredd"){
      return this.freddRoutes.filter(a => a.type == type);      
    } else if(module == "crm"){
      return this.CRMRoutes.filter(a => a.type == type);      
    } else if(module == "bfs"){
      return this.BFSRoutes.filter(a => a.type == type);      
    } 
  }

  getHomeRoute(){
    this.subApp.title = "";
    this.subApp.route = "";
    return '/app/home';
  }


}

<mat-form-field appearance="outline" class="w-100">
        <mat-label>{{label}}</mat-label>
        <input 
        autocomplete="off"
        type="text"
        MatInputCurrency
        matInput  
        #name="ngModel"
        [required]="required" 
        [(ngModel)]="model"     
        (ngModelChange)="onChange(model)">
        <!-- <mat-icon matPrefix class="me-2 icon-color">attach_money</mat-icon> -->
        <mat-hint>{{hint}}</mat-hint>
        <mat-error *ngIf="name.invalid || name.dirty">This field is required</mat-error>
</mat-form-field>
    
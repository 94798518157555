export class Common {
  public static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  queryBuilder(url: string, query: any): string {
    if (query) {
      if (query.select) {
        url += '$select=' + query.select;
      }
      if (query.filter) {
        url += ((url === '') ? '' : '&') + '$filter=' + query.filter;
      }
      if (query.expand) {
        url += ((url === '') ? '' : '&') + '$expand=' + query.expand;
      }
      if (query.orderby) {
        url += ((url === '') ? '' : '&') + '$orderby=' + query.orderby;
      }
      if (query.skip) {
        url += ((url === '') ? '' : '&') + '$skip=' + query.skip;
      }
      if (query.top) {
        url += ((url === '') ? '' : '&') + '$top=' + query.top;
      }
     
    }
    return url;
  }

  querySelect(url: string, query: any): string {
    if (query) {
      if (query.select) {
        url += '$select=' + query.select;
      }      
    }
    return url;
  }

  queryExpand(url: string, query: any): string {
    if (query) {
     
      if (query.expand) {
        url += '$expand=' + query.expand;
      }     
    }
    return url;
  }


}
<mat-toolbar [class]="theme.myTopNavColor" class="shadow">
    <button type="button" mat-icon-button (click)="onMenuClick()" >
      <mat-icon>home</mat-icon>
    </button>    
    <!-- <button type="button" mat-icon-button (click)="onMenuClick()" >
      <mat-icon>home</mat-icon>
    </button> -->
    <span>
      <button *ngIf="!routeService.subApp.title" mat-button type="button" class="apptitle" (click)="onAppClick()">
        <span >{{appName}}</span>
        
      </button>      
      <span *ngIf="routeService.subApp.title" (click)="onSubAppClick()">  
      <span [hidden]="routeService?.subApp?.title == 'FREDD'">
          {{routeService?.subApp?.title}}
      </span>  
      <img [hidden]="routeService?.subApp?.title != 'FREDD'" src="assets/icons/FREDD100x50White.png" alt="">
      </span>
    </span>
        
    <!-- <span class="spacer"></span>
    <button mat-button [matMenuTriggerFor]="aboveMenu">East</button>
<mat-menu #aboveMenu="matMenu" yPosition="above">
  <button mat-menu-item>Item 1</button>
  <button mat-menu-item>Item 2</button>
</mat-menu>

<button mat-button [matMenuTriggerFor]="belowMenu">West</button>
<mat-menu #belowMenu="matMenu" yPosition="below">
  <button mat-menu-item>Item 1</button>
  <button mat-menu-item>Item 2</button>
</mat-menu>

<button mat-button [matMenuTriggerFor]="beforeMenu">Formely Owned</button>
<mat-menu #beforeMenu="matMenu" xPosition="before">
  <button mat-menu-item>Item 1</button>
  <button mat-menu-item>Item 2</button>
</mat-menu>


<button mat-button [matMenuTriggerFor]="afterMenu">UK</button>
<mat-menu #afterMenu="matMenu" xPosition="after">
  <button mat-menu-item>Item 1</button>
  <button mat-menu-item>Item 2</button>
</mat-menu> -->
    <!-- <span class="userIcon" *ngIf="authenticate.isLoggedIn"><mat-icon class="ms-1 me-1">person</mat-icon> Sameer Patel </span> -->
    
    <!-- <button mat-button class="favorite-icon" (click)="onlogoutClick()"  matTooltip="Log Out">
      <mat-icon>logout</mat-icon>
    </button>  -->
   
    <button class="backbutton bRed" mat-mini-fab [matMenuTriggerFor]="menu">
      {{baseService.profileName}}
  </button>
  
  <mat-menu #menu="matMenu">
      <p class="text-center mt-2 ">
          <b> {{baseService.username}} </b>
      </p>
      <mat-divider></mat-divider>
      <button mat-menu-item  *ngIf="baseService.IsAdmin == true" (click)="openAdminPortal()" >
          <mat-icon class="me-1">home</mat-icon> <span>Admin Portal</span>
      </button>
      <button mat-menu-item (click)="logout()">
          <mat-icon class="me-1">logout</mat-icon> <span>Log Out</span>
      </button>
      
  </mat-menu>
</mat-toolbar>
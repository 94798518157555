<!-- <div class="shadow-lg p-2 mt-90 rounded">
    <app-my-heading heading="Categories"></app-my-heading>
    <div class="row g-2">
            <div id="outerContainer">
                <div class="pdf-container">
                    <pdf-viewer
                    [src]="pdfSrc"
                    [rotation]="0"
                    [original-size]="false"
                    [show-all]="true"
                    [fit-to-page]="false"
                    [zoom]="1"
                    [zoom-scale]="'page-width'"
                    [stick-to-page]="false"
                    [render-text]="true"
                    [external-link-target]="'blank'"
                    [autoresize]="true"
                    [show-borders]="false">
                    </pdf-viewer>
                </div>
            </div>
    </div>
</div> -->


<div class="top-ribbon-dialog" style="display: flex !important; align-items: center  !important;" [class]="theme.myTopNavColor">
    <h2 class="ms-3" style="margin-bottom: 0px">PDF</h2>        
    <span class="spacer"></span>
    <app-ribbon-cancel-dialog mat-dialog-close></app-ribbon-cancel-dialog>
</div>

<div >

        <p class="mt-5" style="border-bottom: solid 1px #d7d7d7;
        color: #3860b2;
        font-weight: 400;">{{documentName}}</p>

        <span class="d-flex justify-content-between">
           
            <input 
            
            [(ngModel)]="search"
            type="search" class="w-100 form-control form-control-sm mysearch" 
            placeholder="Search"  autocomplete="off">

            <span class="d-flex">                
                  <mat-icon [hidden]="!(search == '' || pdfQuery !=  search)"  (click)="searchQueryChanged(search, false)" class="add-icon ms-2" style="color:black;">search</mat-icon>                
                  <mat-icon [hidden]="!(search != '' && pdfQuery ==  search)"  (click)="searchQueryChanged(search, true)" class="add-icon ms-2" style="color:black;">arrow_back_ios</mat-icon>
                  <mat-icon [hidden]="!(search != '' && pdfQuery ==  search)" (click)="searchQueryChanged(search, false)" class="add-icon" style="color:black;">arrow_forward_ios</mat-icon>
              <!-- <button [hidden]="!(search == '' || pdfQuery !=  search)" [disabled]="search == ''" type="button" (click)="searchQueryChanged(search, false)" mat-icon-button class="addbtn" >
                  <mat-icon class="add-icon" style="color:black;">search</mat-icon>
              </button>
              <button [hidden]="!(search != '' && pdfQuery ==  search)" [disabled]="search == ''" type="button" (click)="searchQueryChanged(search, true)" mat-icon-button class="addbtn ms-2" >
                  <mat-icon class="add-icon" style="color:black;">arrow_back_ios</mat-icon>
              </button>
              <button [hidden]="!(search != '' && pdfQuery ==  search)" [disabled]="search == ''" type="button" (click)="searchQueryChanged(search, false)" mat-icon-button class="addbtn" >
                  <mat-icon class="add-icon" style="color:black;">arrow_forward_ios</mat-icon>
              </button> -->
            </span>

        </span>
        <!-- <p>Total Matches: {{totalMatches}}</p> -->
        
     
        <div id="outerContainer">
          <div class="pdf-container">
            <pdf-viewer
             
              [src]="pdfSrc"
              [rotation]="0"
              [original-size]="true"
              [show-all]="true"
              [fit-to-page]="true"
              [zoom]="1"
              [zoom-scale]="'page-width'"
              [stick-to-page]="false"
              [render-text]="true"
              [external-link-target]="'blank'"
              [autoresize]="true"
              [show-borders]="false"
              >
            </pdf-viewer>
          </div>
        </div>
      </div>


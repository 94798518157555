import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './modules/main/home/home.component';
import { PageNotFoundComponent } from './modules/main/page-not-found/page-not-found.component';
import { LandingPageComponent } from './modules/main/landing-page/landing-page.component';
import { AuthGuardService } from './services/base/auth-guard.service';
import { LoginComponent } from './modules/main/login/login.component';

import { MsalGuard } from '@azure/msal-angular';
import { SigninComponent } from './modules/main/signin/signin.component';
import { FreddwebAdminListComponent } from './modules/freddweb/components/freddweb-admin-list/freddweb-admin-list.component';
import { FreddwebAdminComponent } from './modules/freddweb/components/freddweb-admin/freddweb-admin.component';
import { AdminPortalComponent } from './modules/main/admin/admin-portal/admin-portal.component';
import { AuthenticateComponent } from './modules/main/authenticate/authenticate.component';

const routes: Routes = [

  { path: "", redirectTo: "/app/home", pathMatch: "full" },
  {
    path: "app", component: HomeComponent, canActivate: [AuthGuardService], runGuardsAndResolvers: 'always',
    children: [
       { path: "home", component: LandingPageComponent },    
       {path: "home/admin/portal" , component : AdminPortalComponent},
      // { path: "freddweb/admin", component: FreddwebAdminComponent },  
      // { path: "freddweb/admin/list", component: FreddwebAdminListComponent },  
       
      { path: 'fredd', canActivate: [AuthGuardService], loadChildren: () => import('./modules/fredd/fredd.module').then(m => m.FreddModule), data: { appName: "FREDD" } },
      { path: 'home', canActivate: [AuthGuardService], loadChildren: () => import('./modules/freddweb/freddweb.module').then(m => m.FreddWebModule), data: { appName: "FREDD Extranet" } },
      
    ]
  },
  { path: "login", component: LoginComponent },
  { path: "signin", component: SigninComponent },
  { path: "authenticate", component: AuthenticateComponent },

  { path: '**', component: PageNotFoundComponent },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes, {
        useHash:false, onSameUrlNavigation:'reload',
        preloadingStrategy: PreloadAllModules,
        initialNavigation: !isIframe ? 'enabled' : 'disabled' // Don't perform initial navigation in iframes
      })
  ],
  exports: [RouterModule]
})


export class AppRoutingModule { }

export const RoutingComponents = [
  HomeComponent,
  LandingPageComponent,
  LoginComponent,
  
]

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticateService } from 'src/app/services/base/authenticate.service';
import { ThemeService } from 'src/app/services/base/theme.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  
  userId:string = "";
  password:string = "";

  constructor(
    public router: Router, 
    public authenticate:AuthenticateService,
    public theme:ThemeService

  ) { }

  ngOnInit(): void {
  }
  
  errorMessage: string = "";




  openLink(){
    this.authenticate.Authenticate(this.userId, this.password).then(res =>{      
      
      if (res == false) {
        this.errorMessage = "User Id or Password is incorrect.";
      } else{
        localStorage.setItem("freddMobileToken", '123');     
        //this.authenticate.isLoggedIn = true; 
        this.router.navigate(['/app/home']);
      }
      
    
    }, error =>{
        if (error.status == 401)
          this.errorMessage = "User Id or Password is incorrect.";
        else {
          this.errorMessage = "Something went wrong!";
          console.log(error);
        }
    });     
  }

  // openLink(){
  //   this.authenticate.Authenticate(this.userId, this.password).then(res=>{
  //     //localStorage.setItem("freddMobileToken", res['headers'].get('Token'));            
  //     this.authenticate.isLoggedIn = true; 
  //     this.router.navigate(['/home/fredd/region']);
  //   }, error =>{
  //       if (error.status == 401)
  //         this.errorMessage = "User Id or Password is incorrect.";
  //       else {
  //         this.errorMessage = "Something went wrong!";
  //         console.log(error);
  //       }
  //   });     
  // }

  validate() {
    if (this.userId == "")
      return true;

    if (this.password == "")
      return true;

    return false;
  }



}

import { Injectable } from '@angular/core';

import * as customConfig from 'src/assets/customConfig.json';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SPOperationsService } from 'src/app/services/base/spoperations.service';
import { AuthenticationService } from 'src/app/helper/authentication.service';




@Injectable({
  providedIn: 'root'
})

export class UserService {
  readonly rootUrl: string = customConfig.cSharpApi;
  graphApi: string = "https://graph.microsoft.com/v1.0/me";
  profile: any = null;


  constructor(
    public http: HttpClient,
    public sp: SPOperationsService,
    public authenticate: AuthenticationService
  ) {

  }

   getCurrecntUser() {
    var azureID = this.authenticate.CurrentUser().Id
   return this.http.get(this.rootUrl +  'Users/GetUserWithAzureID/' + azureID)
  }
  async getProfile() {

    if (this.profile != null) return;

    await this.http.get(this.graphApi)
      .toPromise().then(profile => {
        this.profile = profile;
        var userNameSplit = String(this.profile.displayName).split(' ');
        var ShortCode = "";
        if (userNameSplit.length > 0)
          ShortCode = userNameSplit[0].substring(0, 1);
        if (userNameSplit.length > 1)
          ShortCode += userNameSplit[1].substring(0, 1);

        this.profile["UserShortCode"] = ShortCode;

      }, error => {
        console.log(error);
      });
  }


  async UpdateUserAzureID(email: string, AzureUserID: string) {
    var obj = {
      "ID": 0,
      "AzureUserId": AzureUserID,
      "Name": "",
      "Email": email
    }
    return this.http.post(this.rootUrl + "Users/UpdateUserAzureID", obj);
  }



  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Ocp-Apim-Subscription-Key': customConfig.azureSubscriptionKey,
      })
    };
    return httpOptions;
  }
}

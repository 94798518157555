import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../loader.service';
import { finalize, catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';


import * as customConfig from 'src/assets/customConfig.json';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(public loaderService:LoaderService, private spinner: NgxSpinnerService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    //this.loaderService.isLoading.next(true);

  // request.headers.set("Token", localStorage.getItem("bmsToken"));
  ///request.headers.append("Token", localStorage.getItem("bmsToken"));
   
  //'Ocp-Apim-Subscription-Key' : customConfig.azureSubscriptionKey,  
    var userIndex = request.url.search("get/user/manual")
    var userGroupIndex = request.url.search("get/user/groups/manual")
    if (userIndex == -1) {
      if(userGroupIndex == -1)
        this.spinner.show();
    }
   
  //  request = request.clone({
  //     setHeaders: {
  //       'Ocp-Apim-Subscription-Key' : customConfig.azureSubscriptionKey,
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*',
  //       'Access-Control-Allow-Headers': 'Content-Type',
  //       'Access-Control-Allow-Methods': '*',        
  //     },
  //   });

   
    return next.handle(request).pipe(
      finalize(
        () =>{
          //this.loaderService.isLoading.next(false);
          this.spinner.hide();
        },      
      )
    );
  }

  // getHeaders(){
  //   const httpOptions = {
  //     headers: new HttpHeaders({        
  //       'Token': localStorage.getItem("bmsToken"),       
  //     })      
  //   };

  //   return httpOptions;
  // }
}

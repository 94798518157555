import { Component, OnInit, Inject, ViewChild, ElementRef, AfterContentInit, ChangeDetectorRef } from '@angular/core';
import { ThemeService } from 'src/app/services/base/theme.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { WindowRefService } from 'src/app/services/base/window-ref.service';

@Component({
  selector: 'app-pdf-view',
  templateUrl: './pdf-view.component.html',
  styleUrls: ['./pdf-view.component.scss']
})
export class PdfViewComponent implements OnInit,AfterContentInit {
 
  @ViewChild(PdfViewerComponent) private pdfComponent: PdfViewerComponent;  
  pdfQuery:string = ''; 
  pdfSrc:string = ""; //"https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  documentName:string = "";  
  search:string = '';

  constructor(                
    public theme:ThemeService, 
    private winRef: WindowRefService,
    public cfr:ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any    
  ) {         
  }
  ngOnInit(): void {   
  }

  ngAfterContentInit(): void {
    this.pdfSrc = this.data.data.pdfSrc;
    this.documentName = this.data.data.documentName;
  }

   // called after pdf page is rendered
   pageRendered() {
   // this.pdfComponent.pdfViewer.currentScaleValue = 'page-fit';
  }

  searchQueryChanged(newQuery: string, previous) {
    
    if (newQuery !== this.pdfQuery) {
      this.pdfQuery = newQuery;
      this.pdfComponent.pdfFindController.executeCommand('find', {
        query: this.pdfQuery,
        caseSensitive: false,
        highlightAll: true
      });
      //this.countMatch();
    } else {
      this.pdfComponent.pdfFindController.executeCommand('findagain', {
        query: this.pdfQuery,
        highlightAll: true,
        findPrevious: previous,
      });
      //this.countMatch();
    }
    
  }

  totalMatches:number = 0;
  searchCount:number = 1;
  countMatch(){
    console.log("array", this.pdfComponent.pdfFindController.pageMatches); 
    console.log("obj " + this.searchCount, this.pdfComponent.pdfFindController.pageMatches[0]); 
    var arrLen = this.pdfComponent.pdfFindController.pageMatches.length;
    console.log("array length", arrLen); 
    
    this.totalMatches = 0;
    console.log("Loop started"); 
    for (let index = 0; index < arrLen; index++) {
      const element = this.pdfComponent.pdfFindController.pageMatches[index];
      console.log("element search No " + this.searchCount, element.length)
      this.totalMatches += element.length;
    }  
    this.cfr.detectChanges();
    console.log("element Total searches " + this.searchCount, this.totalMatches)
    this.searchCount++;
}


  /*
  searchF(stringToSearch: string) {
    this.pdfComponent.pdfFindController.executeCommand('find', {
      caseSensitive: false, findPrevious: true, highlightAll: true, phraseSearch: true, query: stringToSearch
    });
  }
  */
  

}

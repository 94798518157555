<mat-form-field appearance="outline" class="w-100">
    <mat-label>{{label}}</mat-label>
    <input 
    type="text"
    matInput  
    mask="(000) 000-0000"
    [showMaskTyped]="true"    
    autocomplete="off"
    #name="ngModel"
    [required]="required" 
    [(ngModel)]="model"     
    (ngModelChange)="onChange(model)">    
    <mat-hint>{{hint}}</mat-hint>
    <!-- <mat-error *ngIf="name.invalid || name.dirty">This field is required</mat-error> -->
    <mat-error *ngIf="name.invalid">Please Enter a valid contact # e.g. (000) 000-0000</mat-error> 
</mat-form-field>